import React from 'react'
import { Button, Columns, Element, Heading, Icon } from 'react-bulma-components'
import { Mission } from '../../api/missions'
import { WorkPeriod } from '../../api/work-periods'
import { useUpdateMutation } from '../../queries/work-periods'
import { useUpdateMutationKey } from '../../queries/missions'
import { MissionAbandonedIcon } from '../icons'
import { useModal } from '../modal'
import ButtonWithTooltip from '../button-with-tooltip/button-with-tooltip'
import { RequestMessage } from '../request-components/request-components'
import { WorkPeriodAbandonedMessage, WorkPeriodValidatedMessage } from './work-period-messages'
import WorkPeriodValidateButton from './validate-button'
import AbandonConfirmation from './modals/abandon-confirmation'
import { MissionAbandonedMessage } from '../missions/mission-messages'
import { useMutationState } from '../../queries'
import { isAuthorizedFor } from '../protected/protected'
import { hasWorkPeriodWithClockingInProgress } from '../../../../backend/src/utils/missions'
import WorkerLabel from '../texts/worker-label'

interface WorkPeriodActionsProps {
  mission: Mission
  workPeriod: WorkPeriod
}

const WorkPeriodActions: React.FC<WorkPeriodActionsProps> = ({ mission, workPeriod }) => {
  const actions = useActions(workPeriod, mission)
  return (
    <>
      {actions.map(
        (
          action,
          index, //TODO: Component?
        ) => (
          <Element key={action.title}>
            <Heading size={5} mb={2}>
              {action.title}
            </Heading>
            <Element mb={2}>{action.description}</Element>
            {action.content}
            {index !== actions.length - 1 && <hr />}
          </Element>
        ),
      )}
    </>
  )
}

export default WorkPeriodActions

interface Action {
  title: string
  description?: string
  content: React.ReactNode
}

const useActions = (workPeriod: WorkPeriod, mission: Mission): Action[] => {
  //Mutations
  const updateWorkPeriodMutation = useUpdateMutation()
  const missionUpdateMutationState = useMutationState(useUpdateMutationKey())
  const modal = useModal()
  return React.useMemo(
    () =>
      [
        isAuthorizedFor(['employerMember'], {
          resource: 'missions',
          name: 'validateWorkPeriod',
        }) && {
          title: 'Valider la Journée de Travail',
          description:
            'Une fois validée, la Journée de Travail ne peut plus être modifiée et plus aucun pointage ne sera pris en compte. Cette action est réversible.',
          content: (
            <Columns>
              {workPeriod.__actions.canBeInvalidated && (
                <Columns.Column>
                  <WorkPeriodValidatedMessage workPeriod={workPeriod} />
                </Columns.Column>
              )}
              <Columns.Column narrow>
                <WorkPeriodValidateButton
                  workPeriod={workPeriod}
                  label="Valider"
                  cancelLabel="Annuler"
                  type="button"
                  onClick={() =>
                    updateWorkPeriodMutation.mutate({
                      missionId: mission._id,
                      workPeriodId: workPeriod._id,
                      isValidated: !workPeriod.isValidated,
                    })
                  }
                />
              </Columns.Column>
            </Columns>
          ),
        },
        isAuthorizedFor(['employerMember'], { resource: 'missions', name: 'abandon' }) && {
          title: 'Abandonner la Mission à partir de ce jour',
          description: (
            <>
              Le <WorkerLabel /> ne pourra plus pointer aucune entrée et sortie pour cette Journée
              de Travail et le restant de la Mission.\ Il sera remis à disposition de l'Agence
              d'Intérim.
            </>
          ),
          content: (
            <>
              <Columns>
                {workPeriod.isAbandoned && (
                  <Columns.Column>
                    <WorkPeriodAbandonedMessage workPeriod={workPeriod} />
                    <RequestMessage mutation={missionUpdateMutationState} />
                  </Columns.Column>
                )}
                {!workPeriod.isAbandoned && mission.isAbandoned && (
                  <Columns.Column>
                    <MissionAbandonedMessage mission={mission} />
                  </Columns.Column>
                )}
                <Columns.Column narrow>
                  <Button.Group>
                    {!workPeriod.isAbandoned && !mission.isAbandoned && (
                      <ButtonWithTooltip
                        type="button"
                        color="danger"
                        onClick={() => modal.setIsDisplayed(true)}
                        disabled={!workPeriod.__actions.canBeAbandoned}
                        tooltip={{
                          text: hasWorkPeriodWithClockingInProgress(mission)
                            ? "Une journée de travail a un pointage d'entrée mais pas de sortie"
                            : 'Mission interrompue ou Journée de Travail Validée',
                          show: !workPeriod.__actions.canBeAbandoned,
                          id: `${mission._id}-cancel-abandonment`,
                        }}
                      >
                        <Icon mr={1}>
                          <MissionAbandonedIcon />
                        </Icon>
                        {'Abandon'}
                      </ButtonWithTooltip>
                    )}
                  </Button.Group>
                </Columns.Column>
              </Columns>
              <AbandonConfirmation
                show={modal.isDisplayed}
                hide={() => modal.setIsDisplayed(false)}
                mission={mission}
                workPeriod={workPeriod}
              />
            </>
          ),
        },
      ].filter(v => v) as Action[],
    [mission, workPeriod, updateWorkPeriodMutation],
  )
}
