import React from 'react'
import DescriptionList from '../description-list/description-list'
import { Employer } from '../../api/organizations'
import { hoursAsNumberToTimeInputValue } from '../../utils/date'

interface EmployerMissionsRulesListDescriptionProps {
  rules?: Employer['rules']['missions']
  labelColumnWidth?: number
}

const EmployerMissionsRulesListDescription: React.FC<EmployerMissionsRulesListDescriptionProps> = ({
  rules,
  labelColumnWidth = 300,
}) => {
  const items = React.useMemo(
    () => [
      {
        label: 'Paiement des abandons de poste',
        value: rules?.abandonmentPayments ? 'Oui' : 'Non',
      },
      {
        label: 'Heure de début de journée',
        value: rules?.dayStartHour ? hoursAsNumberToTimeInputValue(rules.dayStartHour) : '-',
      },
      {
        label: 'Heure de début de nuit',
        value: rules?.nightStartHour ? hoursAsNumberToTimeInputValue(rules.nightStartHour) : '-',
      },
    ],
    [rules],
  )

  return <DescriptionList items={items} labelColumnWidth={labelColumnWidth} />
}

export default EmployerMissionsRulesListDescription
