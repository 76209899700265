# Quoi de neuf TeamTim ?

Ce document reprend de façon chronologique les mises à jour récentes de la plateforme TeamTim

---

## 24 mars 2025

  - Possibilité pour les ETT et EU de visualiser et d'imprimer les QR codes de leurs intérimaires/salariés

---

## 21 Mars 2025

##### Amélioration de la vue Abonnement
  - Simplification de l'interface
  - Renommage des libellés pour plus de clarté

---

## 20 Mars 2025

  - Renforcement de la validation des interactions utilisateur côté serveur

---

## 19 Mars 2025

  - Correction d'un bug pour les E.U. qui ne pouvaient suggérer dans leurs Demandes un Intérimaire n'ayant pas complété son profil

---

## 21 Février 2025

##### Adaptations au Récapitulatif pour les ETT et EU
  - Les colonnes Semaine Validée et Semaine Pré-facturée sont maintenant fixes
  - L'icône pour les événements de type Prestation est bleue
  - Correction d'un bug d'affichage lors de l'utilisation conjointe des filtres Semaine/Jour/Compact
  - La section Mission renseigne le nom de l'Employeur pour les EU 
  - La section Mission renseigne le nom du client pour les ETT
  - Les boutons pour les Journées de Travail non Validables sont plus discrets

##### Invitation des Travailleurs
  - Possibilité de modifier l'adresse email des travailleurs n'ayant pas encore activé leur compte

##### Vue Paie
  - Affichage des totaux en format décimal dans les tableaux de paie

---

## 20 Février 2025

  - Correction d'un bug permettant d'ajouter un événement à une Journée de Travail Validée
  - Ajustement des couleurs et contrastes de certains éléments difficilement lisibles 

---

## 18 Février 2025

##### Filtres
  - Nouveau filtre Employeur qui regroupe les anciens filtres Type de Contrat et Agence
  - Le filtre "Pré-Facturé" n'est plus sur "Non" par défaut

##### Validation des Journées de Travail
  - Une Journée de Travail **vide** ne peut plus être validée
  - Pour être Validable une Journée de Travail doit :
    - soit avoir un pointage d'entrée et sortie, manuel ou forcé
    - soit avoir un Evénement de type Informatif
    - soit avoir un Evénement de type Prestation
    - soit être Interrompue
    - soit être en Abandon de Poste
  
---

## 14 Février 2025

##### Adaptations au Récapitulatif pour les ETT et EU
  - En Mode Compact plusieurs missions d'une même personne peuvent être compactées sur une même ligne
  - Désactiver le Mode Compact affiche le détail des missions, une par ligne 
  - Valider une Journée de Travail n'exige plus de double confirmation
  - L'indicateur de statut Semaine Validée est plus visible
  - L'indicateur de statut Semaine Préfacturée est plus visible
  - Le bouton pour dévalider une Journée de Travail est plus discret
  - Suppression du raccourci pour Interrompre une Mission
  - Suppression du raccourci pour Valider une Semaine
  - Filtrer par Journée n'affiche plus de lignes vides

---

## 13 Février 2025

  - Correction d'un bug lié à l'application rétro-active de Règles de Pointages sur des Journées de Travail existantes

---

## 11 Février 2025

  - Une EU peut maintenant configurer ses Services via les Paramètres  

---

## 10 Février 2025

  - Amélioration du filtre permettant d'afficher les Demandes d'Intérimaires expirées  

---

## 7 Février 2025

  - Correction d'un bug d'affichage sur des oridnateurs utilisant le mode sombre par défaut
  - Correction d'un bug lié au paramétrage des heures de jour et de nuit pour les EU
  - Correction d'un bug d'affichage de l'indicateur de statut de validation d'une Journée de Travail

---

## 5 Février 2025

  - Les bornes de scan se mettent à jour de façon automatique sans validation
  - Correction d'un bug empêchant l'envoi de Demandes impliquant des Intérimaires n'ayant pas complété leur profil

---