export default {
  history: {
    title: {
      missionCreated: 'Mission créée',
      missionUpdated: 'Mission mise à jour',
      missionValidated: 'Mission validée',
      missionUnValidated: 'Mission dé-validée',
      missionCancelled: 'Mission interrompue',
      clockingRegistered: 'Pointage enregistré',
      workPeriodCreated: 'Journée de travail ajoutée',
      workPeriodUpdated: 'Journée de travail mise à jour',
      workPeriodRemoved: 'Journée de travail supprimée',
      preBillingRegistered: 'Mission pré-facturée',
      workPeriodsUpdated: 'Journées de Travail mises à jour',
    },
    changesTitle: {
      'workPeriodCreated': 'Ajout',
      'workPeriodRemoved': 'Suppression',
      'workPeriodUpdated': 'Mise à jour',
      'workPeriod.start.date': 'Heure de début',
      'workPeriod.end.date': 'Heure de fin',
      'workPeriod.isValidated': 'Journée de travail validée',
      'workPeriod.start.manualClocking': "Pointage forcé ajouté pour l'entrée",
      'workPeriod.end.manualClocking': 'Pointage forcé ajouté pour la sortie',
      'workPeriod.isAbandoned': 'Mission abandonnée',
      'workPeriod.isCancelled': 'Mission interrompue',
      isValidated: ' ',
      isAbandoned: ' ',
    },
  },
}
