import { sortBy } from 'lodash'
import { MissionWithSummary } from '../api/missions'
import React from 'react'

//Start: the date of start of earliest work period
//End: the date of start of lastest work period
const useMissionsStartAndEndDates = (missions: MissionWithSummary[]): { start: Date; end: Date } =>
  React.useMemo(() => {
    const sortedDates = sortBy(
      missions.flatMap(mission => mission.workPeriods.map(wp => wp.start.date)),
    )
    return {
      start: sortedDates[0] || new Date(),
      end: sortedDates[sortedDates.length - 1] || new Date(),
    }
  }, [missions])

export default useMissionsStartAndEndDates
