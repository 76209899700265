import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { queryClient, QueryOptions, UseMutationResult, useResource, useResources } from '.'
import {
  create,
  fetch,
  get,
  WorkersRequest,
  WorkersRequestCreationPayload,
  WorkersRequestUpdatePayload,
  update,
  CreatePostPayload,
  createPost,
  UpdatePostPayload,
  updatePost,
  deletePost,
  DeletePostPayload,
  assignWorkerToPost,
  AssignWorkerToPostPayload,
  count,
} from '../api/workers-requests'
import useStore from '../store'
import { FiltersResourcesEnum } from '../store/filters'
import { Mission } from '../api/missions'

//Queries
export const useWorkersRequestQuery = (id?: string): UseQueryResult<WorkersRequest, any> =>
  useResource<WorkersRequest>('workers-requests', get, id)

export const useWorkersRequestOfMissionQuery = (
  missionId?: Mission['_id'],
): UseQueryResult<WorkersRequest[], any> =>
  useResources<WorkersRequest[], any>('workers-requests', fetch, {
    'posts.missions': missionId,
  })

export const useWorkersRequestsCountQuery = (
  filters?: any,
  options?: QueryOptions,
): UseQueryResult<number, any> => {
  return useResources<number, any>(
    'workers-requests/count',
    count,
    filters || useStore(state => state.filters[FiltersResourcesEnum.workersRequests]),
    options,
  )
}

export const useWorkersRequestsQuery = (
  filters?: any,
  options?: QueryOptions,
): UseQueryResult<WorkersRequest[], any> => {
  return useResources<WorkersRequest[], any>(
    'workers-requests',
    fetch,
    filters || useStore(state => state.filters[FiltersResourcesEnum.workersRequests]),
    options,
  )
}

//Mutations
const onMutationSuccess = () => {
  queryClient.invalidateQueries(['workers-requests'])
  queryClient.invalidateQueries(['workers'])
}

export const useCreateMutationKey = (): QueryKey => ['workers-requests', 'create']
export const useCreateMutation = (): UseMutationResult<
  WorkersRequest,
  WorkersRequestCreationPayload,
  any
> => useMutation(create, { mutationKey: useCreateMutationKey(), onSuccess: onMutationSuccess })

const useUpdateMutationKey = (): QueryKey => ['workers-requests', 'update']
export const useUpdateMutation = (): UseMutationResult<
  WorkersRequest,
  WorkersRequestUpdatePayload,
  any
> =>
  useMutation(update, {
    mutationKey: useUpdateMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useCreatePostMutationKey = (): QueryKey => ['workers-requests', 'create-post']
export const useCreatePost = (): UseMutationResult<WorkersRequest, CreatePostPayload, any> =>
  useMutation(createPost, {
    mutationKey: useCreatePostMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useUpdatePostMutationKey = (): QueryKey => ['workers-requests', 'update-post']
export const useUpdatePost = (): UseMutationResult<WorkersRequest, UpdatePostPayload, any> =>
  useMutation(updatePost, {
    mutationKey: useUpdatePostMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useDeletePostMutationKey = (): QueryKey => ['workers-requests', 'delete-post']
export const useDeletePost = (): UseMutationResult<WorkersRequest, DeletePostPayload, any> =>
  useMutation(deletePost, {
    mutationKey: useDeletePostMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useAssignWorkerToPostMutationKey = (): QueryKey => ['workers-requests', 'update-post']
export const useAssignWorkerToPost = (): UseMutationResult<
  WorkersRequest,
  AssignWorkerToPostPayload,
  any
> =>
  useMutation(assignWorkerToPost, {
    mutationKey: useAssignWorkerToPostMutationKey(),
    onSuccess: onMutationSuccess,
  })
