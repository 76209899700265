import React from 'react'
import { Button, Columns, Container, Element, Form, Icon, Section } from 'react-bulma-components'
import { CellProps, Column } from 'react-table'
import Layout from '../../components/layout/layout'
import Table from '../../components/table'
import { WorkPeriodWithMissionAndSummary } from '../../api/work-periods'
import { Link } from 'react-router-dom'
import { ViewListIcon } from '@heroicons/react/solid'
import WorkPeriodValidateConfirmationModal from '../../components/work-periods/modals/validate-confirmation'
import { formatCompleteDate, formatTime, localDate } from '../../utils/date'
import WorkPeriodValidateButton from '../../components/work-periods/validate-button'
import ComponentHeader from '../../components/sections/component-header'
import Protected from '../../components/protected/protected'
import { SessionSlice } from '../../store/session'
import useStore from '../../store'
import { useWorkPeriodsQuery } from '../../queries/work-periods'
import {
  WorkPeriodAbandonedMessage,
  WorkPeriodCancelledMessage,
  WorkPeriodValidatedMessage,
} from '../../components/work-periods/work-period-messages'
import {
  workPeriodSummaryHeaders,
  WorkPeriodSummaryValue,
} from '../../components/table/summary-cell'
import { getUserLabel } from '../../utils/users'
import { FiltersResourcesEnum } from '../../store/filters'
import useFilters from '../../hooks/filters'
import WorkerLabel from '../../components/texts/worker-label'
import { WorkPeriodsFilters } from '../../components/work-periods/filters'
import { endOfDay, parseISO, startOfDay } from 'date-fns'
import PageTitle from '../../components/pages/page-title'
import ListPageLayout from '../../components/layout/list-page-layout'

const WorkPeriodsList: React.FunctionComponent = () => {
  const { filters } = useFilters(FiltersResourcesEnum.workPeriods)
  const query = useWorkPeriodsQuery({
    ...filters,
    start: startOfDay(parseISO(filters.start)),
    end: endOfDay(parseISO(filters.start)),
    summary: true,
  })

  const [currentWorkPeriodToValidate, setCurrentWorkPeriodToValidate] = React.useState<
    WorkPeriodWithMissionAndSummary | undefined
  >()

  const currentUserRole = useStore(state => state.session.currentUserRole)
  const columns = useColumns(setCurrentWorkPeriodToValidate, currentUserRole)

  const action = (
    <Button renderAs={Link} to="/missions/" outlined color="primary" size="small">
      <Icon>
        <ViewListIcon />
      </Icon>
      <span>Liste des Missions</span>
    </Button>
  )

  return (
    <ListPageLayout
      title="Liste des Journées de Travail"
      subtitle={formatCompleteDate(filters.start)}
      action={action}
      filters={<WorkPeriodsFilters />}
    >
      <Table
        columns={columns}
        data={query.data ?? []}
        noDataMessage="Aucune Journée de travail à afficher"
      />
      {currentWorkPeriodToValidate && (
        <WorkPeriodValidateConfirmationModal
          workPeriod={currentWorkPeriodToValidate}
          hide={() => setCurrentWorkPeriodToValidate(undefined)}
        />
      )}
    </ListPageLayout>
  )
}

export default WorkPeriodsList

const useColumns = (
  setCurrentWorkPeriodToValidate: React.Dispatch<
    React.SetStateAction<WorkPeriodWithMissionAndSummary | undefined>
  >,
  currentUserRole: SessionSlice['currentUserRole'],
): Column<WorkPeriodWithMissionAndSummary>[] =>
  React.useMemo(() => {
    const columns: (Column<WorkPeriodWithMissionAndSummary> | false)[] = [
      {
        Header: <WorkerLabel />,
        id: 'user',
        accessor: workPeriod => workPeriod.mission.user.firstName,
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return (
            <span style={{ whiteSpace: 'nowrap' }}>{getUserLabel(workPeriod.mission.user)}</span>
          )
        },
      },
      {
        Header: 'Journée',
        accessor: workPeriod => workPeriod.start.date,
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return (
            <>
              <Protected roles={['employerMember']}>
                <Link to={`/missions/${workPeriod.mission._id}/work-periods/${workPeriod._id}`}>
                  {localDate(workPeriod.start.date)}
                </Link>
              </Protected>
              <Protected roles={['worker']}>{localDate(workPeriod.start.date)}</Protected>
            </>
          )
        },
      },
      {
        Header: 'Début Th.',
        accessor: 'start.theoritical',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return (
            <Element renderAs="span" textColor="dark">
              {formatTime(new Date(workPeriod.start.date))}
            </Element>
          )
        },
        width: 1,
      },
      {
        Header: 'Fin Th.',
        accessor: 'end.theoritical',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod: WorkPeriodWithMissionAndSummary = data.cell.row.original
          return (
            <Element renderAs="span" textColor="dark">
              {formatTime(new Date(workPeriod.end.date))}
            </Element>
          )
        },
        width: 1,
      },
      {
        Header: 'Début',
        accessor: 'start',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return (
            <WorkPeriodSummaryValue
              workPeriod={workPeriod}
              mission={workPeriod.mission}
              workPeriodSummaryHeader={workPeriodSummaryHeaders[0]}
            />
          )
        },
        width: 1,
      },
      {
        Header: 'Fin',
        accessor: 'end',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod: WorkPeriodWithMissionAndSummary = data.cell.row.original
          return (
            <WorkPeriodSummaryValue
              workPeriod={workPeriod}
              mission={workPeriod.mission}
              workPeriodSummaryHeader={workPeriodSummaryHeaders[1]}
            />
          )
        },
        width: 1,
      },
      currentUserRole !== 'interimAgencyMember' && {
        Header: 'Agence',
        accessor: workPeriod => workPeriod.mission.interimAgency?.name,
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return workPeriod.mission.interimAgency?.name ?? null
        },
      },
      currentUserRole !== 'employerMember' && {
        Header: 'Employeur',
        accessor: workPeriod => workPeriod.mission.employer.name,
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return workPeriod.mission.employer?.name || ' '
        },
      },
      {
        Header: 'Service',
        accessor: workPeriod => workPeriod.mission.service.name,
      },
      {
        Header: 'Poste',
        accessor: workPeriod => workPeriod.mission.jobTitle.name,
      },
      currentUserRole !== 'worker' && {
        Header: (
          <WorkPeriodValidatedMessage
            workPeriod={{ isValidated: true }}
            type="icon"
            small
            iconColor={'dark'}
          />
        ),
        accessor: 'isValidated',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod: WorkPeriodWithMissionAndSummary = data.cell.row.original
          return (
            <>
              <WorkPeriodValidatedMessage workPeriod={workPeriod} type="icon" small />
            </>
          )
        },
        width: 1,
      },
      currentUserRole !== 'worker' && {
        Header: (
          <WorkPeriodAbandonedMessage
            workPeriod={{ isAbandoned: true }}
            type="icon"
            small
            iconColor={'dark'}
          />
        ),
        accessor: 'isAbandoned',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod: WorkPeriodWithMissionAndSummary = data.cell.row.original
          return <WorkPeriodAbandonedMessage workPeriod={workPeriod} type="icon" small />
        },
        width: 1,
      },
      currentUserRole !== 'worker' && {
        Header: (
          <WorkPeriodCancelledMessage
            workPeriod={{ isCancelled: true }}
            type="icon"
            small
            iconColor={'dark'}
          />
        ),
        accessor: 'isCancelled',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod: WorkPeriodWithMissionAndSummary = data.cell.row.original
          return <WorkPeriodCancelledMessage workPeriod={workPeriod} type="icon" small />
        },
        width: 1,
      },
      currentUserRole !== 'worker' && {
        Header: 'Mission',
        accessor: 'mission',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod: WorkPeriodWithMissionAndSummary = data.cell.row.original
          return (
            <>
              {localDate(workPeriod.mission.start)}&nbsp;-&nbsp;{localDate(workPeriod.mission.end)}
            </>
          )
        },
      },
      currentUserRole !== 'worker' && {
        Header: 'Valider',
        accessor: workPeriod => workPeriod.__actions.canBeValidated,
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return (
            <Protected
              roles={['employerMember']}
              action={{ resource: 'missions', name: 'validateWorkPeriod' }}
            >
              <WorkPeriodValidateButton
                workPeriod={workPeriod}
                size="smallest"
                onClick={() => setCurrentWorkPeriodToValidate(workPeriod)}
              />
            </Protected>
          )
        },
      },
    ]
    return columns.flatMap(c => (c ? [c] : []))
  }, [currentUserRole])
