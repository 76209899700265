import React, { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { useFirstRender } from '../hooks/use-first-render'
import useStore from '../store'
import { SessionSlice } from '../store/session'
import useUserLegalConditionsVersionsStatus from '../hooks/use-user-legal-conditions-status'
import { ProtectedAction, isProtectedFor } from '../components/protected/protected'
import {
  isAuthorizedForOption,
  OrganizationOptionProtectedProps,
} from '../components/protected/organization-option-protected'
import useSoftReload from '../hooks/use-soft-reload'

interface AuthenticatedProps {
  roles?: SessionSlice['currentUserRole'][]
  action?: ProtectedAction
  organizationOptions?: OrganizationOptionProtectedProps['options']
  children: React.ReactNode
}

const Authenticated: React.FC<AuthenticatedProps> = ({
  children,
  roles,
  action,
  organizationOptions,
}) => {
  const softReload = useSoftReload()
  const legalConditionsStatus = useUserLegalConditionsVersionsStatus()
  const location = useLocation()
  const [isAuthenticated, currentUserRole, currentOrganization, currentUser] = useStore(
    ({ session }) => [
      session.isAuthenticated,
      session.currentUserRole,
      session.currentOrganization,
      session.currentUser,
    ],
    shallow,
  )

  //Reload app when current user role change (change of profile, organization, login as...)
  const firstRender = useFirstRender()
  useEffect(() => {
    if (!firstRender) softReload('/')
  }, [currentUserRole, currentOrganization?._id])

  // Listen session data to check if current user can access to current route
  if (!isAuthenticated) {
    if (location.pathname.includes('/kiosk')) {
      return (
        <Navigate
          to="/login?redirect=/kiosk"
          state={{
            message: {
              color: 'warning',
              header: "Création d'une Borne de Scan",
              body: "Connectez-vous en tant qu'Employeur pour bloquer cet appareil comme Borne de Scan",
            },
          }}
        />
      )
    } else {
      if (location.pathname.includes('/login')) return children
      else return <Navigate to="/login" />
    }
  }
  // User is authenticated !

  //Redirect to privacy policy page when user has not yet accepted the latest version
  if (legalConditionsStatus.privacyPolicy === 'outdated') return <Navigate to="/privacy-policy" />
  //Redirect to privacy policy page when user has not yet accepted the latest version
  if (legalConditionsStatus.termsOfUse === 'outdated') return <Navigate to="/terms-of-use" />
  //Redirect to / when current user is authenticated but does not have the authorized role to access the current route
  if (roles && isProtectedFor(roles, action)) return <Navigate to="/" />
  if (organizationOptions && !isAuthorizedForOption(organizationOptions)) return <Navigate to="/" />
  if (currentUserRole === 'guest') return <Navigate to="/errors/no-active-role" />

  //@ts-expect-error fix appAccess types...
  if (currentUser?.appAccess === 'system') {
    const path = location.pathname
    if (
      !path.includes('/laser-scanner') &&
      !path.includes('/qr-scanner') &&
      !path.includes('/kiosk')
    ) {
      return <Navigate to="/kiosk" />
    }
  }
  // All is ok
  return <>{currentUser && children}</>
}

export default Authenticated
