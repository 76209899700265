
import { ServiceRepositoryCreationPayload } from '../../../backend/src/services/resources/services/services.repository.service.types'
import api, { ApiResponse } from '.'
import { ServiceSchema } from '../../../backend/src/services/resources/services/service.types'

export interface Service extends ServiceSchema {
  _id: string
}

const POPULATE = ['employer']

/**
 * Fetch all services with optional params
 */
export const fetch = async (params?: any): Promise<ApiResponse<Service[]>> =>
  await api({
    endpoint: '/services',
    method: 'get',
    params: {
      sort: '-createdAt',
      populate: POPULATE,
      ...(params || {}),
    },
  })

/**
 * Get a single service by ID
 */
export const get = async (id: string): Promise<ApiResponse<Service>> =>
  await api({
    endpoint: `/services/${id}`,
    method: 'get',
    params: {
      populate: POPULATE,
    },
  })

export type ServiceCreationPayload = Omit<ServiceRepositoryCreationPayload, 'employer'>

/**
 * Create a new service
 */
export const create = async (params: ServiceCreationPayload): Promise<ApiResponse<Service>> =>
  await api({
    endpoint: '/services',
    method: 'post',
    params,
  })

export interface ServiceUpdatePayload extends Partial<ServiceCreationPayload> {
  id: string
}

/**
 * Update an existing service
 */
export const update = async (params: ServiceUpdatePayload): Promise<ApiResponse<Service>> => {
  const { id, ...payload } = params
  return await api({
    endpoint: `/services/${id}`,
    method: 'put',
    params: payload,
  })
}