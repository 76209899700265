import React from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { useOrganizationQuery } from '../../queries/organizations'
import { InterimAgency } from '../../api/organizations'
import { CurrencyEuroIcon, UserGroupIcon } from '@heroicons/react/outline'
import { EmployerIcon, PreBillingIcon, TerminalIcon, WorkerIcon } from '../../components/icons'
import { ViewListIcon } from '@heroicons/react/solid'
import SidebarLayout from '../../components/layout/sidebar-layout'
import useStore from '../../store'
import { isAuthorizedFor } from '../../components/protected/protected'

export interface OutletContext {
  interimAgency: InterimAgency
}

interface InterimAgencySettingsWrapperProps {
  mode: string
}

const InterimAgencySettingsWrapper: React.FC<InterimAgencySettingsWrapperProps> = ({ mode }) => {
  const { id } = useParams()
  const location = useLocation()
  const { data } = useOrganizationQuery<InterimAgency>(id, 'interimAgency', {
    enabled: mode !== 'currentOrganization',
  })
  const currentOrganization = useStore(state => state.session.currentOrganization)
  let interimAgency: InterimAgency | undefined
  if (mode === 'currentOrganization') {
    interimAgency = currentOrganization as InterimAgency
  } else {
    interimAgency = data
  }

  const menu = {
    missionsAndWorkPeriods: {
      title: 'Missions & Journées de Travail',
      sections: [
        {
          path: 'workers',
          label: 'Intérimaires',
          icon: WorkerIcon,
        },
      ],
    },
    collaboration: {
      title: 'Collaboration',
      sections: [
        {
          path: 'partner-employers',
          label: 'Entreprises Partenaires',
          icon: EmployerIcon,
        },
      ],
    },
    subscriptions: {
      title: 'Abonnements et consommations',
      sections: [
        {
          path: 'app-subscription-periods/new',
          label: 'En cours',
          icon: PreBillingIcon,
        },
        {
          path: 'app-subscription-periods',
          label: 'Historique',
          icon: ViewListIcon,
        },
      ],
    },

    settings: {
      title: 'Paramètres',
      sections: [
        {
          path: 'info',
          label: "Détails de l'Agence",
          icon: UserGroupIcon,
        },
        {
          path: 'terminals',
          label: 'Bornes de Pointage',
          icon: TerminalIcon,
        },
      ],
    },
  }

  if (!isAuthorizedFor(['interimAgencyMember'])) {
    menu.missionsAndWorkPeriods.sections = []
  }

  if (isAuthorizedFor(['interimAgencyMember'])) {
    menu.collaboration.sections.push({
      path: 'members',
      label: 'Gestionnaires de mon agence',
      icon: UserGroupIcon,
    })
  }

  if (isAuthorizedFor(['superAdmin'])) {
    menu.settings.sections.push({
      path: 'app-subscription-options',
      label: "Coûts d'abonnement",
      icon: CurrencyEuroIcon,
    })
  }

  if (!interimAgency) {
    return <></>
  }

  if (location.pathname === '/settings') {
    return <Navigate to="/settings/info" replace />
  }

  return (
    <SidebarLayout<OutletContext>
      basePath={mode === 'currentOrganization' ? '/settings' : `/agencies/${id}`}
      menu={menu}
      outletContext={{ interimAgency }}
      menuTitle={isAuthorizedFor(['superAdmin']) ? interimAgency.name : undefined}
    />
  )
}

export default InterimAgencySettingsWrapper
