import React from 'react'
import { useWorkersRequestsQuery } from '../../queries/workers-requests'
import { DashboardCard } from './dashboard-card'
import { startOfDay, subDays } from 'date-fns'

export const WorkersRequestsDashboardCard: React.FC = () => {
  const workersRequestsQuery = useWorkersRequestsQuery({
    isExpired: subDays(startOfDay(new Date()), 3),
    isComplete: false,
    isCancelled: false,
    fields: ['_id', 'stats'],
    populate: [],
  })

  const activeWorkersRequests = workersRequestsQuery?.data || []

  const totalActiveWorkersRequests = activeWorkersRequests?.length ?? 0
  const totalWorkersNotAssigned =
    activeWorkersRequests?.reduce((acc, curr) => {
      return acc + curr.stats.notAssigned
    }, 0) ?? 0
  const totalWorkersRequested =
    activeWorkersRequests?.reduce((acc, curr) => {
      return acc + curr.stats.notAssigned + curr.stats.assigned
    }, 0) ?? 0

  return (
    <>
      <DashboardCard
        title={'DEMANDES EN COURS'}
        mainStat={{ value: totalActiveWorkersRequests, label: 'Demandes' }}
        secondaryStat={totalWorkersNotAssigned + ' / ' + totalWorkersRequested + ' Postes Vacants'}
        to={`/workers-requests`}
        queryStatus={workersRequestsQuery.status}
      />
    </>
  )
}
