import React from 'react'
import { OrganizationTypeEnum } from '../../../api/organizations'
import MultiSelectField from '../../form/fields/multi-select'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import { buildListAsInputOptions } from '../../../utils/forms'
import { useAssociatedOrganizationsQuery } from '../../../queries/organizations'
import SelectField from '../../form/fields/select'
import useStore from '../../../store'

interface OrganizationFilterFieldProps {
  disabledQueries?: boolean
  queriesStaleTime?: number
  type: OrganizationTypeEnum
  singleSelect?: boolean
}

const OrganizationFilterField: React.FC<OrganizationFilterFieldProps> = ({
  type,
  disabledQueries,
  queriesStaleTime,
  singleSelect,
}) => {
  const currentOrganization = useStore(state => state.session.currentOrganization)
  const organizationsQuery = useAssociatedOrganizationsQuery({
    enabled: !disabledQueries,
    staleTime: queriesStaleTime,
  })

  const options = buildListAsInputOptions(organizationsQuery.data || [])

  if (currentOrganization?.type === OrganizationTypeEnum.employer) {
    options.push({
      label: currentOrganization.name,
      value: null,
    })
  }

  return (
    <FieldColumn>
      <Field
        label={type === OrganizationTypeEnum.employer ? 'Entreprises Utilisatrices' : 'Employeur'}
        name={type === OrganizationTypeEnum.employer ? 'employer' : 'interimAgency'}
        component={singleSelect ? SelectField : MultiSelectField}
        options={options}
      />
    </FieldColumn>
  )
}

export default OrganizationFilterField
