import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './employer-settings-wrapper'
import { useServicesQuery } from '../../queries/services'
import ServicesList from '../../components/services/list'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import { Box } from 'react-bulma-components'
import { AddResourceIcon } from '../../components/icons'
import ServiceExplanation from '../../components/services/service-explanation'
import ServiceModalForm from '../../components/services/service-modal-form'
import { Service } from '../../api/services'
import { sortByIgnoreCase } from '../../utils/arrays'

/**
 * Component for managing employer services
 */
const ServicesSettings: React.FC = () => {
  const { employer } = useOutletContext<OutletContext>()
  const { data: services } = useServicesQuery({
    enabled: Boolean(employer),
  })
  const [currentService, setCurrentService] = React.useState<Service | 'new' | undefined>()

  return (
    <>
      <SidebarContentTitle
        title="Services"
        onButtonClick={() => setCurrentService('new')}
        addButtonLabel="Nouveau service"
        ButtonIcon={AddResourceIcon}
        help={<ServiceExplanation textColor={'white'} />}
      />
      <Box>
        <ServicesList
          data={sortByIgnoreCase(services || [], 'name')}
          onServiceClick={service => setCurrentService(service)}
        />
      </Box>
      <ServiceModalForm
        isDisplayed={Boolean(currentService)}
        setIsDisplayed={() => setCurrentService(undefined)}
        service={currentService === 'new' ? undefined : currentService}
      />
    </>
  )
}

export default ServicesSettings
