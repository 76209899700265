import React from 'react'
import { WorkPeriod } from '../../api/work-periods'
import {
  MissionAbandonedIcon,
  MissionNotEditableIcon,
  WorkPeriodCancelledIcon,
  WorkPeriodCannotBeValidatedIcon,
  WorkPeriodEventIcon,
  WorkPeriodNotValidatedIcon,
  WorkPeriodValidatedIcon,
} from '../icons'
import Message, { CommonMessageProps } from '../message/message'
import { buildEventsLabels } from '../../utils/work-periods'

export const WorkPeriodAbandonedMessage: React.FC<
  CommonMessageProps & { workPeriod: { isAbandoned: WorkPeriod['isAbandoned'] } }
> = ({ workPeriod, ...rest }) => {
  if (!workPeriod.isAbandoned) return <></>
  return (
    <Message
      iconColor="danger"
      text="Journée de Travail en Abandon de Poste"
      icon={<MissionAbandonedIcon />}
      {...rest}
    />
  )
}
export const WorkPeriodValidatedMessage: React.FC<
  CommonMessageProps & { workPeriod: { isValidated: WorkPeriod['isValidated'] } }
> = ({ workPeriod, ...rest }) => {
  if (!workPeriod.isValidated) return <></>
  return (
    <Message
      text="Journée de travail Validée"
      iconColor="success"
      icon={<WorkPeriodValidatedIcon />}
      {...rest}
    />
  )
}
export const WorkPeriodNotValidatedMessage: React.FC<
  CommonMessageProps & { workPeriod: { isValidated: WorkPeriod['isValidated'] } }
> = ({ workPeriod, ...rest }) => {
  if (workPeriod.isValidated) return <></>
  return (
    <Message
      text="Journée de travail non Validée"
      iconColor="info"
      icon={<WorkPeriodNotValidatedIcon />}
      {...rest}
    />
  )
}
export const WorkPeriodCannotBeValidated: React.FC<
  CommonMessageProps & {
    workPeriod: {
      isValidated: WorkPeriod['isValidated']
      __actions: { canBeValidated: WorkPeriod['__actions']['canBeValidated'] }
    }
  }
> = ({ workPeriod, ...rest }) => {
  if (workPeriod.__actions.canBeValidated || workPeriod.isValidated) return <></>
  return (
    <Message
      text="Journée de travail incomplète"
      iconColor="warning"
      icon={<WorkPeriodCannotBeValidatedIcon />}
      {...rest}
    />
  )
}

const WorkPeriodNonEditableMessage: React.FC<WorkPeriodMessagesProps> = ({
  workPeriod,
  ...rest
}) => {
  if (!workPeriod.isValidated) return <></>
  return (
    <Message text="Journée de Travail non éditable" icon={<MissionNotEditableIcon />} {...rest} />
  )
}

export const WorkPeriodCancelledMessage: React.FC<
  CommonMessageProps & { workPeriod: { isCancelled: WorkPeriod['isCancelled'] } }
> = ({ workPeriod, ...rest }) => {
  if (!workPeriod.isCancelled) return <></>
  return (
    <Message
      iconColor={'danger'}
      text="Journée de Travail interrompue"
      icon={<WorkPeriodCancelledIcon />}
      {...rest}
    />
  )
}

export const WorkPeriodEventsMessage: React.FC<
  CommonMessageProps & { workPeriod: { events: WorkPeriod['events'] }; text?: string }
> = ({ workPeriod, ...rest }) => {
  const hasBenefitEvent = workPeriod.events.some(e => e.item.type === 'benefit')
  if (hasBenefitEvent) {
    rest.color = 'info'
    rest.iconColor = 'info'
  }
  return (
    <Message text={buildEventsLabels(workPeriod.events)} icon={<WorkPeriodEventIcon />} {...rest} />
  )
}

interface WorkPeriodMessagesProps extends CommonMessageProps {
  workPeriod: WorkPeriod
}

const WorkPeriodMessages = ({ workPeriod, ...rest }: WorkPeriodMessagesProps): JSX.Element => {
  return (
    <>
      <WorkPeriodAbandonedMessage workPeriod={workPeriod} {...rest} />
      <WorkPeriodValidatedMessage workPeriod={workPeriod} {...rest} />
      {/* <WorkPeriodNonEditableMessage workPeriod={workPeriod} {...rest} /> */}
      <WorkPeriodCancelledMessage workPeriod={workPeriod} {...rest} />
    </>
  )
}

export default WorkPeriodMessages
