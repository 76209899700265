import { get, sortBy as _sortBy } from 'lodash'
import { Paths } from 'type-fest'

interface ListInputItem<ListItem extends string | Record<string, unknown>> {
  value?: string | null
  label: string
  item?: ListItem
  renderedLabel?: React.ReactNode
}

export const buildListAsInputOptions = <ListItem extends string | Record<string, any>>(
  list: ListItem[],
  options?: {
    sortBy?: string | null | ((item: ListInputItem<ListItem>) => boolean)
    labelKeyPath?: Paths<ListItem>
    valueKeyPath?: Paths<ListItem>
    labelBuilder?(item: ListItem): React.ReactNode
    renderLabel?(item: ListItem): React.ReactNode
    blank?: {
      label: string
      value?: string
    }
  },
): ListInputItem<ListItem>[] => {
  if (!options) options = {}
  const { blank, sortBy } = options
  if (blank && !blank.value) blank.value = ''
  blank
  const items: ListInputItem<ListItem>[] = list.map(listItem => {
    const sanitized: ListInputItem<ListItem> = {
      value:
        typeof listItem === 'string' ? listItem : get(listItem, options?.valueKeyPath || '_id'),
      label:
        typeof listItem === 'string'
          ? listItem
          : options?.labelBuilder
          ? options.labelBuilder(listItem)
          : get(listItem, options?.labelKeyPath || 'name'),
      item: listItem,
      renderedLabel: options?.renderLabel && options.renderLabel(listItem),
    }
    return sanitized
  })
  const itemsToReturn: ListInputItem<ListItem>[] = [...(blank ? [blank] : []), ...items]
  if (sortBy === null) {
    return itemsToReturn
  }
  return _sortBy(itemsToReturn, sortBy ? sortBy : 'label')
}
