import { DocumentAddIcon, EyeIcon } from '@heroicons/react/outline'
import React from 'react'
import { Button, Element, Icon } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import { CellProps, Column } from 'react-table'
import { WorkersRequest } from '../../api/workers-requests'
import { isAuthorizedFor } from '../../components/protected/protected'
import Table from '../../components/table'
import {
  WorkersRequestCancelledMessage,
  WorkersRequestClosedMessage,
  WorkersRequestCompleteMessage,
} from '../../components/workers-requests/workers-requests-messages'
import { useWorkersRequestsQuery } from '../../queries/workers-requests'
import { localDate } from '../../utils/date'
import WorkersRequestsFilters from '../../components/workers-requests/filters'
import WorkerLabel from '../../components/texts/worker-label'
import { DuplicateIcon } from '../../components/icons'
import Protected from '../../components/protected/protected'
import ListPageLayout from '../../components/layout/list-page-layout'

const WorkersRequestsList: React.FC = () => {
  const query = useWorkersRequestsQuery()
  const columns = useListTableColumns()

  const action = (
    <Protected roles={['employerMember']}>
      <Button renderAs={Link} to="/workers-requests/new" color="primary" size="small">
        <Icon>
          <DocumentAddIcon />
        </Icon>
        <Element renderAs="span">Nouvelle demande d'Intérimaires</Element>
      </Button>
    </Protected>
  )

  return (
    <ListPageLayout
      title="Liste des Demandes d'Intérimaires"
      subtitle="Toutes les Demandes d'Intérimaires auprès des Agences partenaires"
      action={action}
      filters={<WorkersRequestsFilters />}
    >
      <Table
        columns={columns}
        data={query.data || []}
        noDataMessage="Aucune Demande de Personnel à afficher"
      />
    </ListPageLayout>
  )
}

export default WorkersRequestsList

const useListTableColumns = (): Column<WorkersRequest>[] => {
  return React.useMemo(
    () =>
      [
        {
          Header: ' ',
          accessor: 'show',
          Cell: (data: CellProps<WorkersRequest>) => {
            const workersRequest = data.cell.row.original
            return (
              <Link to={`/workers-requests/${workersRequest._id}`} data-test="workers-request-link">
                <Icon>
                  <EyeIcon />
                </Icon>
              </Link>
            )
          },
          width: '40px',
        },
        isAuthorizedFor(['employerMember'])
          ? {
              Header: '  ',
              accessor: 'duplicate',
              Cell: (data: CellProps<WorkersRequest>) => {
                const workersRequest = data.cell.row.original
                return (
                  <Link
                    to={`/workers-requests/new?workersRequestTemplate=${workersRequest._id}`}
                    data-test="workers-request-duplicate-link"
                  >
                    <Icon>
                      <DuplicateIcon />
                    </Icon>
                  </Link>
                )
              },
              width: '40px',
            }
          : {
              accessor: 'duplicate',
            },
        {
          Header: 'Employeur',
          accessor: 'employer',
          Cell: (data: CellProps<WorkersRequest>) => {
            const workersRequest = data.cell.row.original
            return <>{workersRequest.employer.name}</>
          },
        },
        {
          Header: "Agence d'intérim",
          accessor: 'interimAgency',
          Cell: (data: CellProps<WorkersRequest>) => {
            const workersRequest = data.cell.row.original
            return <>{workersRequest.interimAgency.name}</>
          },
        },
        {
          Header: <WorkerLabel plural />,
          id: 'stats',
          accessor: 'stats',
          Cell: (data: CellProps<WorkersRequest>) => {
            const workersRequest = data.cell.row.original
            return (
              <Link to={`/workers-requests/${workersRequest._id}`}>
                {workersRequest.stats.assigned}&nbsp;/&nbsp;
                {workersRequest.stats.assigned + workersRequest.stats.notAssigned}
              </Link>
            )
          },
        },
        {
          Header: 'Début',
          accessor: 'missionsStart',
          Cell: (data: CellProps<WorkersRequest>) => {
            const workersRequest = data.cell.row.original
            return localDate(workersRequest.missionsStart)
          },
        },
        {
          Header: 'Fin',
          accessor: 'missionsEnd',
          Cell: (data: CellProps<WorkersRequest>) => {
            const workersRequest = data.cell.row.original
            return localDate(workersRequest.missionsEnd)
          },
        },
        {
          Header: 'Service',
          accessor: 'missionData.service.name',
        },
        {
          Header: (
            <WorkersRequestClosedMessage workersRequest={{ isClosed: true }} type="icon" small />
          ),
          accessor: 'isClosed',
          Cell: (data: CellProps<WorkersRequest>) => {
            const workersRequest = data.cell.row.original
            return <WorkersRequestClosedMessage workersRequest={workersRequest} type="icon" small />
          },
          width: '40px',
        },
        {
          Header: (
            <WorkersRequestCompleteMessage
              workersRequest={{ isComplete: true }}
              type="icon"
              small
            />
          ),
          accessor: 'isComplete',
          Cell: (data: CellProps<WorkersRequest>) => {
            const workersRequest = data.cell.row.original
            return (
              <WorkersRequestCompleteMessage workersRequest={workersRequest} type="icon" small />
            )
          },
          width: '40px',
        },
        {
          Header: (
            <WorkersRequestCancelledMessage
              workersRequest={{ isCancelled: true }}
              type="icon"
              small
            />
          ),
          accessor: 'isCancelled',
          Cell: (data: CellProps<WorkersRequest>) => {
            const workersRequest = data.cell.row.original
            return (
              <WorkersRequestCancelledMessage workersRequest={workersRequest} type="icon" small />
            )
          },
          width: '40px',
        },
      ] as Column<any>[],
    [],
  )
}
