import React, { useMemo } from 'react'
import { Column } from 'react-table'
import Table from '../table'
import { formatPreciseCost } from '../../utils/finance'
import { AppSubscriptionPeriod } from '../../api/app-subscription-periods'
import { InfoIcon } from '../icons'
import { isNumber } from 'lodash'
import { Button, Icon } from 'react-bulma-components'

interface TableRow {
  name: string
  unitPrice: number | undefined | React.ReactNode
  unitType: string
  quantity: number | undefined
  total: number
}

interface AppSubscriptionPeriodTableProps {
  period: AppSubscriptionPeriod
  onTerminalDetailsClick?: () => void
}

/**
 * Tableau détaillant les tarifs et coûts d'une période d'abonnement
 */
const AppSubscriptionPeriodTable: React.FC<AppSubscriptionPeriodTableProps> = ({
  period,
  onTerminalDetailsClick,
}) => {
  const columns = useMemo<Column<TableRow>[]>(() => {
    const baseColumns: Column<TableRow>[] = [
      {
        Header: 'Tarifs',
        accessor: 'name',
      },
      {
        Header: 'Prix unitaire HT',
        accessor: 'unitPrice',
        Cell: ({ value }) => (value && isNumber(value) ? formatPreciseCost(value) : value || ''),
      },
      {
        Header: 'Unité',
        accessor: 'unitType',
      },
      {
        Header: 'Quantité',
        accessor: 'quantity',
      },
      {
        Header: 'Total HT',
        accessor: 'total',
        Cell: ({ value }) => (value ? formatPreciseCost(value) : '0€'),
      },
    ]

    return baseColumns
  }, [period])

  const tableData = useMemo(() => {
    const rows: TableRow[] = [
      {
        name: "Licence d'utilisation par EU",
        unitPrice: period.pricing.licenseFee,
        unitType: 'Semaine',
        quantity: period.metrics.licenseCount,
        total: period.fees.totalLicenseFees,
      },
      {
        name: 'Location des bornes',
        unitPrice: (
          <Button
            onClick={() => onTerminalDetailsClick?.()}
            color="ghost"
            size="small"
            p={1}
            data-test="app-subscription-period-terminal-details-button"
          >
            <Icon>
              <InfoIcon />
            </Icon>
          </Button>
        ),
        unitType: 'Semaine',
        quantity: period.metrics.weeklyTerminalsCumulative,
        total: period.fees.totalTerminalFees,
      },
      ...period.pricing.tiers.map((tier, index) => ({
        name: `Utilisation de ${tier.workersRange.min} à ${tier.workersRange.max || '∞'} salariés`,
        unitPrice: tier.workPeriodCost,
        unitType: 'Salarié / jour',
        quantity: period.fees.tierTotals[index]?.workPeriodsCount || 0,
        total: period.fees.tierTotals[index]?.totalCost || 0,
      })),
      {
        name: 'Total HT',
        unitPrice: undefined,
        unitType: '',
        quantity: undefined,
        total: period.fees.total,
      },
    ]

    return rows
  }, [period])

  return (
    <Table
      columns={columns}
      data={tableData}
      className="is-bordered"
      dataTest="app-subscription-period-table"
    />
  )
}

export default AppSubscriptionPeriodTable
