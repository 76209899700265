import React from 'react'
import Layout from '../../components/layout/layout'
import useUrlId from '../../hooks/use-url-id'
import { usePreBillingQuery } from '../../queries/pre-billings'
import { Button, Card, Container, Level, Section } from 'react-bulma-components'
import PreBillingPageTitle from '../../components/pre-bilings/titles/pre-billing-page-title'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import SummaryTable from '../../components/summary/summary-table'
import { useSummaryQuery } from '../../queries/missions'
import SummaryHeader from '../../components/summary/summary-header'
import ExportButton from '../../components/summary/export-button'
import useMissionsStartAndEndDates from '../../hooks/use-missions-start-end-dates'
import PreBillingEditForm from '../../components/pre-bilings/edit-pre-billing-form'
import Modal, { useModal } from '../../components/modal'
import Protected from '../../components/protected/protected'
import useMissionsFromSummaryQuery from '../../hooks/use-missions-from-summary-query'

const PreBillingDetails: React.FC = ({}) => {
  const { id } = useUrlId()
  const preBillingQuery = usePreBillingQuery(id)
  const preBilling = preBillingQuery.data

  const formModal = useModal()

  const summaryQuery = useSummaryQuery(
    {
      'workPeriods.preBilling': id ? [id] : [],
      week: preBilling?.missions.week as number,
      year: preBilling?.missions.year as number,
    },
    { enabled: Boolean(preBilling) },
  )
  const missions = useMissionsFromSummaryQuery(summaryQuery.data?.users || [])

  const [tableCompactMode, setTableCompactMode] = React.useState(true)

  const { start, end } = useMissionsStartAndEndDates(missions)

  const breadcrumbs = useBreadcrumbs()

  if (!preBilling) return <></>

  return (
    <Layout>
      <Section>
        <Container max={true}>
          <Level>
            <Level.Side>
              <Breadcrumbs items={breadcrumbs} />
            </Level.Side>
            <Level.Side align="right">
              <Protected
                roles={[
                  { role: 'employerMember', action: { resource: 'preBillings', name: 'create' } },
                ]}
              >
                {!preBilling.invoiceRef && (
                  <Button color={'success'} mr={2} onClick={() => formModal.setIsDisplayed(true)}>
                    Ajouter un numéro de facture
                  </Button>
                )}
              </Protected>
              <ExportButton
                filters={{ 'workPeriods.preBilling': id ? [id] : [], start: start, end: end }}
                hideCustom
              />
            </Level.Side>
          </Level>
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <PreBillingPageTitle
                preBilling={preBilling}
                onInvoiceRefClick={() => formModal.setIsDisplayed(true)}
              />
            </Level.Side>
          </Level>
          {preBilling && start && end && (
            <SummaryHeader
              missions={missions}
              startOfWeek={start}
              endOfWeek={end}
              interimAgencyId={preBilling.interimAgency._id}
              totals={preBilling.missions.totals}
              forceCompleteMode
            />
          )}

          <Card className="summary-table">
            <Card.Content>
              <Button onClick={() => setTableCompactMode(!tableCompactMode)} mb={2} size={'small'}>
                Passer en mode {tableCompactMode ? 'complet' : 'compact'}
              </Button>
              <SummaryTable
                missions={missions}
                start={start}
                end={end}
                displayDay={undefined}
                summaryMinimalMode={tableCompactMode}
                hideActions
                employerRules={summaryQuery.data?.employerRules || []}
              />
            </Card.Content>
          </Card>
          <Protected
            roles={[
              { role: 'employerMember', action: { resource: 'preBillings', name: 'create' } },
            ]}
          >
            <Modal actions={formModal} title={`Ajouter un numéro de facture`}>
              <PreBillingEditForm preBilling={preBilling} />
            </Modal>
          </Protected>
        </Container>
      </Section>
    </Layout>
  )
}

export default PreBillingDetails

const useBreadcrumbs = () =>
  React.useMemo(
    () => [
      {
        label: 'Pré-Facturations',
        to: '/pre-billings',
      },
      {
        label: `Pré-Facturation`,
      },
    ],
    [],
  )
