import { ExclamationIcon, PrinterIcon } from '@heroicons/react/solid'
import React from 'react'
import { Button, Element, Icon } from 'react-bulma-components'
import { User } from '../../api/users'
import { useUserQrQuery } from '../../queries/users'
import { useModal } from '../modal'
import RegenerateQrConfirmation from './modals/regenerate-qr-confirmation'

interface UserQrCodeProps {
  user: User
  print?: boolean
  canRegenerate?: boolean
}

const UserQrCode: React.FunctionComponent<UserQrCodeProps> = ({ user, print, canRegenerate }) => {
  const currentQrQuery = useUserQrQuery(user._id)
  const modal = useModal()
  return (
    <Element display="inline-block">
      {currentQrQuery.data && (
        <img data-test="user-qr-code" src={`data:image/png;base64, ${currentQrQuery.data}`} />
      )}
      <Button.Group px={4} justifyContent="space-between">
        {print && (
          <Button renderAs={'a'} href={`/print-qr/${user._id}`} target="_blank" color={'primary'}>
            <Icon>
              <PrinterIcon />
            </Icon>
            <Element renderAs="span">Imprimer</Element>
          </Button>
        )}
        {canRegenerate && (
          <Button
            color="primary"
            onClick={() => modal.setIsDisplayed(true)}
            data-test={`regenerate-qr-code-${user._id}`}
          >
            <Icon>
              <ExclamationIcon />
            </Icon>
            <Element renderAs="span">Générer un Nouveau QR Code</Element>
          </Button>
        )}
      </Button.Group>
      <RegenerateQrConfirmation
        show={modal.isDisplayed}
        hide={() => modal.setIsDisplayed(false)}
        user={user}
      />
    </Element>
  )
}
export default UserQrCode
