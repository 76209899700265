import React from 'react'
import { Service } from '../../api/services'
import { useCreateMutation, useUpdateMutation } from '../../queries/services'
import { Field, Form, Formik } from 'formik'
import InputField from '../form/fields/input'
import TextareaField from '../form/fields/textarea'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import useStore from '../../store'

export interface ServiceFormProps {
  service?: Service
}

const ServiceForm: React.FC<ServiceFormProps> = ({ service }) => {
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()

  return (
    <Formik
      initialValues={service || { name: '', description: '' }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.name) errors.name = 'Champ requis'
        return errors
      }}
      onSubmit={values => {
        service
          ? updateMutation.mutate({ ...values, id: service._id })
          : createMutation.mutate({ ...values })
      }}
    >
      {() => {
        return (
          <Form autoComplete="off">
            <Field
              label="Nom"
              name="name"
              component={InputField}
              placeholder="Ex: Production, Logistique, Administration..."
            />
            <Field
              label="Description"
              name="description"
              component={TextareaField}
              placeholder="Description du service (optionnelle)"
            />
            <RequestButton
              color="primary"
              type="submit"
              mutation={service ? updateMutation : createMutation}
            >
              {service ? 'Mettre à jour le Service' : 'Nouveau Service'}
            </RequestButton>
            <RequestMessage mutation={service ? updateMutation : createMutation} />
          </Form>
        )
      }}
    </Formik>
  )
}

export default ServiceForm
