import React from 'react'
import { Button, Element, Icon } from 'react-bulma-components'
import { Size } from 'react-bulma-components/src/components'
import { WorkPeriod } from '../../api/work-periods'
import { MissionNotEditableIcon, MissionValidatedIcon } from '../icons'
import { DotsHorizontalIcon } from '@heroicons/react/outline'

interface WorkPeriodValidateButtonProps {
  workPeriod: WorkPeriod
  label?: string
  cancelLabel?: string
  size?: Size | 'smallest'
  type?: 'button' | 'submit' | 'reset'
  onClick(workPeriod: WorkPeriod): void
}

const WorkPeriodValidateButton: React.FunctionComponent<WorkPeriodValidateButtonProps> = ({
  label,
  cancelLabel,
  onClick,
  workPeriod,
  size,
  type,
}) => {
  if (!workPeriod.isValidated) {
    const actionDisabled = !workPeriod.__actions.canBeValidated
    return (
      <Element
        renderAs="span"
        data-tooltip={
          actionDisabled
            ? 'Impossible de valider la journée de travail'
            : 'Valider la journée de travail'
        }
      >
        <Button
          style={size === 'smallest' ? { height: '1.5rem' } : {}}
          size={size === 'smallest' ? 'small' : size}
          type={type}
          color={actionDisabled ? '' : 'link'}
          onClick={() => onClick(workPeriod)}
          disabled={actionDisabled}
          data-test="work-period-validate-action"
        >
          <Icon>{actionDisabled ? <DotsHorizontalIcon /> : <MissionValidatedIcon />}</Icon>
          {label && <span>{label}</span>}
        </Button>
      </Element>
    )
  } else if (workPeriod.__actions.canBeInvalidated) {
    return (
      <Element renderAs="span">
        <Button
          style={size === 'smallest' ? { height: '1.5rem' } : {}}
          size={size === 'smallest' ? 'small' : size}
          type={type}
          color={cancelLabel ? undefined : 'ghost'}
          onClick={() => onClick(workPeriod)}
          data-test="work-period-invalidate-action"
        >
          <Icon size={'small'} data-tooltip="Journée de travail validée" color="grey-light">
            <MissionNotEditableIcon />
          </Icon>
          {cancelLabel && <span>{cancelLabel}</span>}
        </Button>
      </Element>
    )
  }
  return <></>
}

export default WorkPeriodValidateButton
