import { Field, Formik } from 'formik'
import React from 'react'
import { Columns } from 'react-bulma-components'
import useFilters from '../../hooks/filters'
import { FiltersResourcesEnum, FiltersSlice, MissionsFilters } from '../../store/filters'
import { dateInputValuesToWeekDates, dateToDateInputValue, formatISODate } from '../../utils/date'
import AutoSubmit from '../form/utils/auto-submit'
import Protected from '../protected/protected'
import CheckboxField from '../form/fields/checkbox'
import DisplayedDaysFilterField from '../filters/fields/displayed-days'
import WorkersFilterField from '../filters/fields/workers'
import ServiceFilterField from '../filters/fields/service'
import OrganizationFilterField from '../filters/fields/organization'
import { OrganizationTypeEnum } from '../../api/organizations'
import JobTitleFilterField from '../filters/fields/job-title'
import WeekFilterField from '../filters/fields/week'
import FieldColumn from '../filters/field-column'
import IsCancelledFilterField from '../filters/fields/is-cancelled'
import IsPrebilledFilterField from '../filters/fields/is-prebilled'
import { IsoDate } from 'src/hooks/use-calendar'

interface MissionFiltersProps {
  showDayFilter?: boolean
  showSummaryMinimalModeFilter?: boolean
  disabledQueries?: boolean
}

type MissionsFiltersFormValues = Omit<
  FiltersSlice[FiltersResourcesEnum.missions],
  'start' | 'end'
> & { week: IsoDate }

const MissionsFilters: React.FunctionComponent<MissionFiltersProps> = ({
  showDayFilter,
  showSummaryMinimalModeFilter,
  disabledQueries,
}) => {
  const { setFilters, filters } = useFilters(FiltersResourcesEnum.missions)

  const queriesStaleTime = 1000 * 60 * 2 // 2 min

  const { start, end, ...missionFilters } = filters

  return (
    <Formik<MissionsFiltersFormValues>
      initialValues={{
        ...missionFilters,
        week: dateToDateInputValue(filters.start),
      }}
      onSubmit={values => {
        const { week, displayDay: displayDayFormValue, ...missionFilters } = values
        const { start, end } = dateInputValuesToWeekDates(week)

        let displayDay: MissionsFiltersFormValues['displayDay'] = displayDayFormValue
        if (week !== formatISODate(filters.start)) {
          // We reset the filter on week change
          // if not, the view is stuck with an invalid displayDay value
          displayDay = undefined
        }

        setFilters(FiltersResourcesEnum.missions, { ...missionFilters, start, end, displayDay })
      }}
      enableReinitialize
    >
      {props => {
        return (
          <>
            <Columns mb="0">
              <WeekFilterField />
              {showDayFilter && (
                <DisplayedDaysFilterField start={filters.start} end={filters.end} />
              )}
              <Protected roles={['employerMember', 'interimAgencyMember']}>
                {showSummaryMinimalModeFilter && (
                  <FieldColumn>
                    <Field
                      label="Mode compact"
                      name="summaryMinimalMode"
                      component={CheckboxField}
                      data-test="summaryMinimalModeCheckbox"
                    />
                  </FieldColumn>
                )}
              </Protected>
            </Columns>

            <Columns mb="0">
              <Protected roles={['employerMember', 'interimAgencyMember']}>
                <WorkersFilterField disabledQueries={disabledQueries} />
                <Protected roles={['employerMember']}>
                  <ServiceFilterField
                    disabledQueries={disabledQueries}
                    queriesStaleTime={queriesStaleTime}
                    employerIds={filters.employer}
                  />
                </Protected>
                <Protected roles={['interimAgencyMember']}>
                  <ServiceFilterField
                    disabledQueries={Boolean(disabledQueries || !filters.employer?.[0])}
                    queriesStaleTime={queriesStaleTime}
                    employerIds={filters.employer}
                  />
                </Protected>
              </Protected>
              <Protected roles={['employerMember']}>
                <OrganizationFilterField
                  disabledQueries={disabledQueries}
                  queriesStaleTime={queriesStaleTime}
                  type={OrganizationTypeEnum.interimAgency}
                />
              </Protected>
              <Protected roles={['interimAgencyMember']}>
                <OrganizationFilterField
                  disabledQueries={disabledQueries}
                  queriesStaleTime={queriesStaleTime}
                  type={OrganizationTypeEnum.employer}
                />
              </Protected>
              <Protected roles={['employerMember']}>
                <JobTitleFilterField
                  disabledQueries={disabledQueries}
                  queriesStaleTime={queriesStaleTime}
                  employerIds={filters.employer}
                />
              </Protected>
              <Protected roles={['employerMember', 'interimAgencyMember']}>
                <IsPrebilledFilterField />
                <IsCancelledFilterField />
              </Protected>
            </Columns>
            <AutoSubmit values={props.values} submitForm={props.submitForm} />
          </>
        )
      }}
    </Formik>
  )
}

export default MissionsFilters
