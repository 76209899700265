import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { queryClient, QueryOptions, UseMutationResult, useResource, useResources } from '.'
import {
  create,
  fetch,
  get,
  remove,
  Service,
  ServiceCreationPayload,
  ServiceUpdatePayload,
  update,
} from '../api/services'

/**
 * Query hook for fetching a single service
 */
export const useServiceQuery = (
  id?: string,
  options?: QueryOptions,
): UseQueryResult<Service, any> => useResource<Service>('services', get, id, undefined, options)

/**
 * Query hook for fetching multiple services
 */
export const useServicesQuery = (
  filters?: any,
  options?: QueryOptions,
): UseQueryResult<Service[], any> =>
  useResources<Service[], any>('services', fetch, filters, options)

// Mutation success handler
const onMutationSuccess = () => {
  queryClient.invalidateQueries(['services'])
}

/**
 * Create mutation key
 */
export const useCreateMutationKey = (): QueryKey => ['services', 'create']

/**
 * Mutation hook for creating a service
 */
export const useCreateMutation = (): UseMutationResult<Service, ServiceCreationPayload, any> =>
  useMutation(create, { mutationKey: useCreateMutationKey(), onSuccess: onMutationSuccess })

/**
 * Update mutation key
 */
export const useUpdateMutationKey = (): QueryKey => ['services', 'update']

/**
 * Mutation hook for updating a service
 */
export const useUpdateMutation = (): UseMutationResult<Service, ServiceUpdatePayload, any> =>
  useMutation(update, {
    mutationKey: useUpdateMutationKey(),
    onSuccess: onMutationSuccess,
  })