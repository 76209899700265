import React from 'react'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import InputField from '../../form/fields/input'

const IsExpiredFilterField: React.FC = ({}) => {
  return (
    <FieldColumn>
      <Field label="Expirée après le" name="isExpired" type="date" component={InputField} />
    </FieldColumn>
  )
}

export default IsExpiredFilterField
