import { useWorkersQuery } from '../queries/users'
import sc from 'string-comparison'
import { getUserLabel, getWorkerAndOrganizationInternalRef } from '../utils/users'
import { sortBy, upperFirst } from 'lodash'
import { User } from '../api/users'
import useStore from '../store'
import { QueryOptions } from '../queries'

interface WorkersSearchResult {
  value: string
  label: string
  rating: number
}

const useWorkersSearch = (
  search: string,
  queryOptions?: QueryOptions<any>,
): WorkersSearchResult[] => {
  const workersQuery = useWorkersQuery({}, { staleTime: 60 * 1000 * 3, ...(queryOptions || {}) })

  const fetchedWorkers = workersQuery.data || []

  const calculateRating = (worker: User): number => {
    if (!search) return 0
    const startOfFirstNameRating = sc.levenshtein.similarity(
      search,
      worker.firstName.substring(0, search.length),
    )
    const startOfLastNameRating = sc.levenshtein.similarity(
      search,
      worker.lastName.substring(0, search.length),
    )
    const globalRating = sc.levenshtein.similarity(search, getUserLabel(worker))
    return (
      startOfFirstNameRating * (search.length <= 2 ? 2 : 1) +
      startOfLastNameRating +
      globalRating / 2
    )
  }

  const workersWithMatchRating = sortBy(
    fetchedWorkers.map(worker => ({
      value: worker._id,
      label: upperFirst(getUserLabel(worker)),
      rating: calculateRating(worker),
    })),
    search ? 'rating' : 'label',
  )
  if (!search) return workersWithMatchRating
  workersWithMatchRating.reverse()

  const bestRating = workersWithMatchRating[0]?.rating || 0
  const limited = workersWithMatchRating
    .slice(0, 6)
    .filter(match => search.length <= 2 || match.rating + bestRating / 4 >= bestRating)

  return limited
}

export default useWorkersSearch
