import { Employer } from '../api/organizations'
import { Terminal } from '../api/terminals'
import { isWithinInterval, startOfDay } from 'date-fns'
import { InterimAgency } from '../api/organizations'

/**
 * Returns the current usage period of a terminal
 * @param terminal - The terminal to check
 * @returns The current usage period or null if no active period is found
 */
export const getCurrentPeriod = (terminal: Terminal): Terminal['usagePeriods'][0] | null => {
  if (!terminal.usagePeriods?.length) {
    return null
  }

  const today = startOfDay(new Date())

  // Find the period that includes today's date
  return (
    terminal.usagePeriods.find(period => {
      const startDate = startOfDay(new Date(period.startDate))
      if (!period.endDate) {
        return startDate <= today
      }

      return isWithinInterval(today, {
        start: startDate,
        end: startOfDay(new Date(period.endDate)),
      })
    }) || null
  )
}

/**
 * Returns all current usage periods of a terminal
 * @param terminal - The terminal to check
 * @returns Array of current usage periods or empty array if no active periods are found
 */
export const getCurrentPeriods = (terminal: Terminal): Terminal['usagePeriods'] => {
  if (!terminal.usagePeriods?.length) {
    return []
  }

  const today = startOfDay(new Date())

  // Find all periods that include today's date
  return terminal.usagePeriods.filter(period => {
    const startDate = startOfDay(new Date(period.startDate))
    if (!period.endDate) {
      return startDate <= today
    }

    return isWithinInterval(today, {
      start: startDate,
      end: startOfDay(new Date(period.endDate)),
    })
  })
}

/**
 * Returns the current organization (employer or interim agency) of a terminal
 * @param terminal - The terminal to check
 * @param organizationType - The type of organization to retrieve ('employer' | 'interimAgency')
 * @returns The current organization or null if no active period is found
 */
export const getCurrentOrganization = <T extends Employer | InterimAgency>(
  terminal: Terminal,
  organizationType: 'employer' | 'interimAgency',
): T | null => {
  const currentPeriod = getCurrentPeriod(terminal)
  return currentPeriod?.[organizationType] as T | null
}

/**
 * Returns the current employer of a terminal
 * The current employer is the one from the usage period that includes today's date
 * If no active period is found, returns null
 */
export const getCurrentEmployer = (terminal: Terminal): Employer | null => {
  return getCurrentOrganization<Employer>(terminal, 'employer')
}

/**
 * Returns the current interim agency of a terminal
 * The current interim agency is the one from the usage period that includes today's date
 * If no active period is found, returns null
 */
export const getCurrentInterimAgency = (terminal: Terminal): InterimAgency | null => {
  return getCurrentOrganization<InterimAgency>(terminal, 'interimAgency')
}
