import React from 'react'
import { Column } from 'react-table'
import Table from '../table'
import { formatDate, formatUtcDate } from '../../utils/date'
import { sortBy } from 'lodash'
import { ResourceLinkCell } from '../table/cells'
import { AppSubscriptionPeriod } from '../../api/app-subscription-periods'
import { CheckIcon } from '../icons'
import { Icon } from 'react-bulma-components'
import { formatPeriodString } from '../../utils/app-subscription-periods'
import { formatCost } from '../../utils/finance'
import { isAuthorizedFor } from '../protected/protected'

interface AppSubscriptionPeriodAgency {
  periodNumber: number
  year: number
  identifier: string
  from: Date
  to: Date
  status: AppSubscriptionPeriod['status']
  invoiceRef?: AppSubscriptionPeriod['invoiceRef']
  totalAmount: number
}

interface AppSubscriptionPeriodsAgencyListProps {
  data: AppSubscriptionPeriodAgency[]
  itemPath?: string
}

/**
 * Composant pour afficher les périodes d'abonnement d'une agence d'intérim
 * @param data - Liste des périodes d'abonnement de l'agence
 */
const AppSubscriptionPeriodsAgencyList: React.FC<AppSubscriptionPeriodsAgencyListProps> = ({
  data,
  itemPath,
}) => {
  const tableColumns = useAgencyPeriodsTableColumns(itemPath)

  return (
    <Table
      columns={tableColumns}
      data={sortBy(data, 'from').reverse()}
      noDataMessage="Aucune période d'abonnement à afficher pour cette agence"
    />
  )
}

/**
 * Hook pour définir les colonnes du tableau des périodes d'une agence
 */
const useAgencyPeriodsTableColumns = (itemPath?: string): Column<AppSubscriptionPeriodAgency>[] => {
  return React.useMemo(() => {
    const columns = [
      {
        Header: 'Période',
        accessor: 'identifier',
        Cell: data => (
          <ResourceLinkCell
            resource={{
              ...data.row.original,
              _id: `${data.row.original.year}_${data.row.original.periodNumber}`,
            }}
            path={itemPath || '/app-subscription-periods/'}
            label={formatPeriodString(data.row.original.year, data.row.original.periodNumber)}
          />
        ),
      },
      {
        Header: 'N° de facture',
        accessor: 'invoiceRef',
      },
      {
        Header: 'Du',
        accessor: 'from',
        Cell: ({ value }: { value: Date }) => formatDate(value),
      },
      {
        Header: 'Au',
        accessor: 'to',
        Cell: ({ value }: { value: Date }) => formatUtcDate(value.toString()),
      },
      {
        Header: 'Total HT',
        accessor: 'totalAmount',
        Cell: ({ value }: { value: AppSubscriptionPeriodAgency['totalAmount'] }) =>
          formatCost(value),
      },
    ]

    if (isAuthorizedFor(['superAdmin'])) {
      columns.splice(columns.length - 1, 0, {
        Header: 'Validée',
        accessor: 'status',
        Cell: ({ value }: { value: AppSubscriptionPeriod['status'] }) =>
          value === 'validated' ? (
            <Icon>
              <CheckIcon />
            </Icon>
          ) : (
            <></>
          ),
      })
    }

    return columns
  }, [itemPath])
}

export default AppSubscriptionPeriodsAgencyList
