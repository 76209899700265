import React from 'react'
import { ModalProps } from '../modal'
import Modal from '../modal'
import ServiceForm, { ServiceFormProps } from './service-form'

interface ServiceModalFormProps extends ServiceFormProps {
  isDisplayed: ModalProps['actions']['isDisplayed']
  setIsDisplayed: ModalProps['actions']['setIsDisplayed']
}

const ServiceModalForm: React.FC<ServiceModalFormProps> = ({
  isDisplayed,
  setIsDisplayed,
  service,
}) => {
  return (
    <Modal
      actions={{ isDisplayed, setIsDisplayed }}
      title={service ? 'Modifier le Service' : 'Créer un nouveau Service'}
    >
      <ServiceForm service={service} />
    </Modal>
  )
}

export default ServiceModalForm 