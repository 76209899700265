import React from 'react'
import { Container, Form, Section, Tabs } from 'react-bulma-components'
import { useLocation } from 'react-router-dom'
import { UserRoleKind } from '../../../../backend/src/services/resources/users/user.model'
import Layout from '../../components/layout/layout'
import { RequestMessage } from '../../components/request-components/request-components'
import ComponentHeader from '../../components/sections/component-header'
import PageHeading from '../../components/sections/page-heading'
import { useMutationState } from '../../queries'
import { useCreateMutationKey, useUsersQuery } from '../../queries/users'
import locales from '../../../../locales'
import useStore from '../../store'
import { User } from '../../api/users'
import { Organization } from '../../api/organizations'
import UsersFilters, { UsersFiltersValues } from '../../components/users/filters'
import CreateOfflineWorkerButton from '../../components/users/buttons/create-offline-worker'
import InviteUserButton from '../../components/users/buttons/invite-user'
import { getWorkerRoleOrAssociationStatus } from '../../utils/users'
import Protected from '../../components/protected/protected'
import UsersTable from '../../components/users/tables/users-table'
import WorkerLabel from '../../components/texts/worker-label'

const UsersList: React.FC = ({}) => {
  const currentOrganization = useStore(state => state.session.currentOrganization as Organization)
  const [filters, setFilters] = React.useState<UsersFiltersValues>({
    // TODO: refactor using filters in store and in http requests
    status: ['accepted', 'pending'],
  })

  const [currentAppAccessTab, setCurrentAppAccessTab] = React.useState<User['appAccess']>('full')

  /* TODO: Review this in v5 */
  const usersRole = useUsersRoleByPathname()

  const query = useUsersQuery({
    'roles.kind': usersRole,
    appAccess: usersRole === 'worker' ? currentAppAccessTab : undefined,
  })

  const tabs: { label: React.ReactNode; value: User['appAccess'] }[] = [
    {
      label: (
        <>
          <WorkerLabel /> Connecté
        </>
      ),
      value: 'full',
    },
    {
      label: (
        <>
          <WorkerLabel /> Offline'
        </>
      ),
      value: 'none',
    },
  ]

  const users = (query.data || []).filter(user => {
    // TODO: refactor using filters in store and in http requests
    const roleOrAssociationStatus = getWorkerRoleOrAssociationStatus(
      user,
      usersRole,
      currentOrganization,
    )
    return (
      !filters.status ||
      !filters.status[0] ||
      !roleOrAssociationStatus ||
      filters.status.includes(roleOrAssociationStatus)
    )
  })

  return (
    <Layout>
      <Form.Field>
        <Form.Label>&nbsp;</Form.Label>
        <Form.Control>
          {usersRole === 'worker' && currentAppAccessTab === 'none' && (
            <CreateOfflineWorkerButton />
          )}
          {currentAppAccessTab !== 'none' && <InviteUserButton userRoleKind={usersRole} />}
        </Form.Control>
      </Form.Field>
      {usersRole === 'worker' && (
        <Tabs>
          {tabs.map(tab => (
            <Tabs.Tab
              key={tab.value}
              active={currentAppAccessTab === tab.value}
              onClick={() => setCurrentAppAccessTab(tab.value)}
              data-test={`workers-list-tab-${tab.value}`}
            >
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs>
      )}
      <UsersTable
        users={users}
        roleKind={
          usersRole === 'worker' && currentAppAccessTab === 'none' ? 'offlineWorker' : usersRole
        }
      />
    </Layout>
  )
}

export default UsersList

const useUsersRoleByPathname = (): UserRoleKind => {
  const location = useLocation()
  return React.useMemo(() => {
    if (location.pathname.match('workers')) return 'worker'
    else if (location.pathname.match('interim-agency-members')) return 'interimAgencyMember'
    else if (location.pathname.match('employer-members')) return 'employerMember'
    return 'superAdmin'
  }, [location.pathname])
}
