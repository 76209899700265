import React from 'react'
import { Form, Panel, Columns, Container, Element, Icon } from 'react-bulma-components'
import ComponentHeader from '../sections/component-header'
import { useOrganizationsQuery } from '../../queries/organizations'
import useStore from '../../store'
import { InterimAgency } from '../../api/organizations'
import { formatDuration, formatTime, getWeek, localDate } from '../../utils/date'
import { formatCost } from '../../utils/finance'
import { MissionWithSummary } from '../../api/missions'
import { useSmicQuery } from '../../queries/smic'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { WeekSummaryTotals } from '../../../../backend/src/services/resources/missions/missions.summary.service.types'
import { isAuthorizedFor } from '../protected/protected'

interface SummaryInfoPanelProps {
  title: string
  data: {
    label: string
    value: string | number | React.ReactNode
  }[]
}
const SummaryInfoPanel: React.FunctionComponent<SummaryInfoPanelProps> = ({ title, data }) => {
  return (
    <Panel style={{ minWidth: 200, boxShadow: 'none' }}>
      <Panel.Header textSize={6} backgroundColor={'white'} textColor="black">
        {title}
      </Panel.Header>
      {data.map(({ label, value }, index) => (
        <Panel.Block key={label + value + index}>
          <Form.Control display="flex" justifyContent="space-between">
            <Element textWeight="bold" textSize={7} mr={3}>
              {label}
            </Element>
            <Element textSize={7}>{value}</Element>
          </Form.Control>
        </Panel.Block>
      ))}
    </Panel>
  )
}

interface SummaryHeaderProps {
  missions: MissionWithSummary[]
  startOfWeek: Date
  endOfWeek: Date
  interimAgencyId?: string
  forceCompleteMode?: boolean
  //service?: string
  // jobTitle?: string
  currentDay?: WeekSummaryTotals['days'][0] & { label: string }
  totals?: WeekSummaryTotals
  missionsCount?: number
}

const SummaryHeader: React.FunctionComponent<SummaryHeaderProps> = ({
  missions,
  startOfWeek,
  endOfWeek,
  interimAgencyId,
  // service,
  totals,
  // jobTitle,
  forceCompleteMode,
  currentDay,
  missionsCount,
}) => {
  const organizationsQuery = useOrganizationsQuery(
    { type: 'interimAgency' },
    { staleTime: 1000 * 60 * 2 },
  )
  const [isSmallMode, setIsSmallMode] = React.useState(forceCompleteMode ? false : true)
  const currentOrganization = useStore(state => state.session.currentOrganization)
  const currentInterimAgency = React.useMemo(
    () =>
      (currentOrganization && currentOrganization.type === 'interimAgency'
        ? currentOrganization
        : (organizationsQuery.data || []).find(
            current => current._id === interimAgencyId,
          )) as InterimAgency,
    [organizationsQuery.data, interimAgencyId],
  )

  const { data } = useSmicQuery(new Date(startOfWeek as Date), {
    enabled: Boolean(startOfWeek) && !isSmallMode,
  })
  const baseLaborHourlyCost = data?.value || 0

  const endOfWeekSmicQuery = useSmicQuery(new Date(endOfWeek as Date), {
    enabled: Boolean(endOfWeek) && !isSmallMode,
  })

  const ChangeModeButton: React.FC = () => {
    if (forceCompleteMode) return <></>
    return (
      <Element textAlign={'right'}>
        <Element renderAs="a" onClick={() => setIsSmallMode(!isSmallMode)} textSize={7}>
          <Icon.Text size={'small'}>
            {isSmallMode ? 'Afficher les détails' : 'Masquer les détails'}
            <Icon>{isSmallMode ? <ChevronDownIcon /> : <ChevronUpIcon />}</Icon>
          </Icon.Text>
        </Element>
      </Element>
    )
  }

  const periodInfoPanelData: SummaryInfoPanelProps['data'] = [
    {
      label: currentDay ? 'Le' : 'Du',
      value: currentDay ? currentDay.label.toLowerCase() : localDate(startOfWeek),
    },
    { label: 'Semaine', value: getWeek(startOfWeek) },
    //{ label: 'Service', value: service ? service : 'tous' },
    {
      label: 'Agence',
      value: currentInterimAgency ? currentInterimAgency.name : 'tous',
    },
    {
      label: 'SMIC',
      value: (
        <Element textAlign={'right'}>
          {baseLaborHourlyCost}€{' '}
          {endOfWeekSmicQuery.data?.value &&
          endOfWeekSmicQuery.data.value !== baseLaborHourlyCost ? (
            <>
              <br />
              {endOfWeekSmicQuery.data.value}€ au{' '}
              {localDate(new Date(endOfWeekSmicQuery.data.from))}
            </>
          ) : (
            ''
          )}
        </Element>
      ),
    },
    {
      label: 'Nbr de missions',
      value: missionsCount || missions.length,
    },
  ]

  if (!currentDay) periodInfoPanelData.splice(1, 0, { label: 'Au', value: localDate(endOfWeek) })

  const employerCostData =
    totals?.employerData?.costs && isAuthorizedFor(['employerMember'])
      ? Object.keys(totals.employerData.costs).map(employerCost => ({
          label: employerCost,
          value: formatCost(totals?.employerData.costs[employerCost]),
        }))
      : []

  if (isSmallMode)
    return (
      <Columns>
        <Columns.Column size={4} m="auto">
          <Element renderAs="strong">
            {currentDay ? (
              currentDay.label
            ) : (
              <>
                Semaine {getWeek(startOfWeek)} - Du {localDate(startOfWeek)} au{' '}
                {localDate(endOfWeek)}
              </>
            )}
          </Element>
        </Columns.Column>
        <Columns.Column size={4} textAlign={'center'}></Columns.Column>
        <Columns.Column size={4} textAlign={'right'}>
          {!currentDay && (
            <>
              TOTAL HT:
              <Element renderAs="strong"> {formatCost(totals?.costs?.total)}</Element>
            </>
          )}
          <ChangeModeButton />
        </Columns.Column>
      </Columns>
    )

  return (
    <Container breakpoint={'fluid'}>
      <ComponentHeader>
        <ComponentHeader.Left>
          <Columns backgroundColor="white-bis">
            <Columns.Column narrow>
              <SummaryInfoPanel title="Informations" data={periodInfoPanelData} />
            </Columns.Column>
            {/*<Columns.Column narrow>
              <SummaryInfoPanel
                title={'Données'}
                data={[
                  { label: 'Panier nuit', value: formatCost(7.1) },
                  {
                    label: 'Heure début nuit',
                    value: formatTime(new Date().setHours(21, 0, 0, 0)),
                  },
                  {
                    label: 'Heure fin nuit',
                    value: formatTime(new Date().setHours(5, 0, 0, 0)),
                  },
                  {
                    label: 'Heure pause',
                    value: formatTime(new Date().setHours(17, 30, 0, 0)),
                  },*
                ]}
              />
            </Columns.Column>*/}

            <Columns.Column narrow>
              {currentDay ? (
                <SummaryInfoPanel
                  title={currentDay.label}
                  data={[
                    // Groupe 1 : Heures travaillées
                    {
                      label: 'Heures travaillées',
                      value: formatDuration(currentDay.durations.worked.total),
                    },
                    {
                      label: '└ Jour',
                      value: formatDuration(currentDay.durations.worked?.day),
                    },
                    {
                      label: '└ Nuit',
                      value: formatDuration(currentDay.durations.worked?.night),
                    },
                    // Groupe 2 : Heures comptabilisées
                    {
                      label: <>Heures d’évènements</>,
                      value: formatDuration(currentDay.durations.benefit?.total),
                    },
                    {
                      label: '└ Jour',
                      value: formatDuration(currentDay.durations.benefit?.day),
                    },
                    {
                      label: '└ Nuit',
                      value: formatDuration(currentDay.durations.benefit?.night),
                    },
                    // Total général
                    {
                      label: 'Total journée',
                      value: formatDuration(currentDay.durations.total?.total),
                    },
                  ]}
                />
              ) : (
                <SummaryInfoPanel
                  title={'Totaux heures'}
                  data={[
                    // Groupe 1 : Heures travaillées
                    {
                      label: 'Travaillées',
                      value: formatDuration(totals?.durations?.worked.total || 0),
                    },
                    {
                      label: '└ Jour',
                      value: formatDuration(totals?.durations?.worked.days || 0),
                    },
                    {
                      label: '└ Nuit',
                      value: formatDuration(totals?.durations?.worked.nights || 0),
                    },
                    {
                      label: <>Heures d’évènements</>,
                      value: formatDuration(totals?.durations?.benefit.total || 0),
                    },
                    {
                      label: '└ Jour',
                      value: formatDuration(totals?.durations?.benefit.day || 0),
                    },
                    {
                      label: '└ Nuit',
                      value: formatDuration(totals?.durations?.benefit.night || 0),
                    },
                    {
                      label: 'Total',
                      value: formatDuration(totals?.durations?.total.total || 0),
                    },
                  ]}
                />
              )}
            </Columns.Column>

            {!currentDay && (
              <Columns.Column narrow>
                <SummaryInfoPanel
                  title={'Totaux coûts'}
                  data={[
                    {
                      label: 'Heures jour base 35',
                      value: formatCost(totals?.costs?.worked?.days),
                    },
                    {
                      label: 'Heures nuit',
                      value: formatCost(totals?.costs?.worked?.nights),
                    },
                    ...employerCostData,
                    {
                      label: 'Total HT',
                      value: (
                        <Element renderAs="strong">{formatCost(totals?.costs?.total)}</Element>
                      ),
                    },
                  ]}
                />
              </Columns.Column>
            )}
          </Columns>
        </ComponentHeader.Left>
        <ComponentHeader.Right>
          <Columns>
            {/*currentInterimAgency && ( // TODO: commented because laborCostCoefficient is not longer on interim agency but setted in job title
              <Columns.Column narrow>
                <SummaryInfoPanel
                  title={currentInterimAgency.name}
                  data={[
                    { label: 'Coefficient', value: currentInterimAgency.laborCostCoefficient },
                    {
                      label: 'Coût horaire',
                      value: formatCost(
                        baseLaborHourlyCost * currentInterimAgency.laborCostCoefficient,
                      ),
                    },
                    {
                      label: 'Coût heure +25%',
                      value: formatCost(
                        baseLaborHourlyCost * currentInterimAgency.laborCostCoefficient * 1.25,
                      ),
                    },
                    {
                      label: 'Coût heure +50%',
                      value: formatCost(
                        baseLaborHourlyCost * currentInterimAgency.laborCostCoefficient * 1.5,
                      ),
                    },
                  ]}
                />
              </Columns.Column>
            ) */}
          </Columns>
        </ComponentHeader.Right>
      </ComponentHeader>
      <ChangeModeButton />
    </Container>
  )
}

export default SummaryHeader
