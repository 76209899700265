import React from 'react'
import { Element, Pagination } from 'react-bulma-components'

interface SummaryPaginationProps {
  currentPage: number
  missionsPerPage: number
  missionsCount: number
  onChange(pageNumber: number): void
}

const SummaryPagination: React.FC<SummaryPaginationProps> = ({
  currentPage,
  missionsPerPage,
  missionsCount,
  onChange,
}) => {
  return (
    <Element my={4}>
      <Element>
        <Pagination
          current={currentPage}
          showFirstLast
          total={Math.ceil(missionsCount / missionsPerPage)}
          onChange={onChange}
          next="Suivant"
          previous="Précédent"
          size={'small'}
        />
      </Element>
      <Element textSize={7} mt={1}>
        {missionsPerPage} travailleurs par page - {missionsCount} travailleurs en tout
      </Element>
    </Element>
  )
}

export default SummaryPagination
