import React from 'react'
import { useRoutes as routerUseRoutes } from 'react-router-dom'
import ClockingsList from '../pages/clockings/list'
import Login from '../pages/login/login'
import MissionDetails from '../pages/missions/details'
import MissionsList from '../pages/missions/list'
import AgenciesList from '../pages/organizations/agencies-list'
import QrScanner from '../pages/qr-scanner/qr-scanner'
import Summary from '../pages/summary'
import UserDetails from '../pages/users/details'
import UsersList from '../pages/users/list'
import UserOrganizations from '../pages/users/user-organizations'
import WorkPeriodsList from '../pages/work-periods/list'
import UserCompleteProfile from '../pages/users/complete-profile'
import UserRequestNewPassword from '../pages/users/request-new-password'
import UserResetPassword from '../pages/users/reset-password'
import UserValidateNewEmail from '../pages/users/validate-new-email'
import WorkPeriodDetails from '../pages/work-periods/details'
import Authenticated from '../containers/authenticated'
import Guest from '../containers/guest'
import WorkerDashboard from '../pages/dashboards/worker-dashboard'
import EmployersList from '../pages/organizations/employers-list'
import EmployerDetails from '../pages/organizations/employer-details'
import AssociationsList from '../pages/associations/list'
import QrPrintPage from '../pages/qr-print/qr-print'
import JobTitleDetailsSettings from '../pages/employer-settings/job-title-details'
import ClockingRuleDetailsSettings from '../pages/employer-settings/clocking-rule-details'
import Protected from '../components/protected/protected'
import SuperAdminDashboard from '../pages/dashboards/super-admin-dashboard'
import InterimAgencyDashboard from '../pages/dashboards/interim-agency-dashboard'
import WorkersRequestsList from '../pages/workers-requests/list'
import WorkersRequestsDetails from '../pages/workers-requests/details'
import WorkersRequestsEdition from '../pages/workers-requests/edition'
import MissionEdition from '../pages/missions/edition'
import { HelpPage } from '../pages/common/help'
import PreBillingsList from '../pages/pre-billings/list'
import PreBillingDetails from '../pages/pre-billings/details'
import { PrivacyPolicyPage } from '../pages/common/privacy-policy'
import { TermsOfUsePage } from '../pages/common/terms-of-use'
import UserNotificationsSettings from '../pages/users/notifications-settings'
import NoActiveRole from '../pages/errors/no-active-role'
import PayrollDetails from '../pages/payroll/details'
import EmployerRulesDetails from '../pages/employer-rules/details'
import LoginAsQrScannerUserPage from '../pages/login/login-as-qr-scanner-user-page'
import BillingDashboard from '../pages/dashboards/billing-dashboard'
import EmployerDashboard from '../pages/dashboards/employer-dashboard'
import { EmployerKPIsPage } from '../pages/kpis/employer-kpis'
import AppSubscriptionPeriodDetails from '../pages/app-subscription-periods/details'
import AppSubscriptionPeriodsList from '../pages/app-subscription-periods/list'
import InterimAgencyWrapper from '../pages/interim-agency-settings/interim-agency-settings-wrapper'
import PartnerEmployers from '../pages/interim-agency-settings/partner-employers'
import AppSubscriptionOptions from '../pages/interim-agency-settings/app-subscription-options'
import AgencyInfoSettings from '../pages/interim-agency-settings/agency-info'
import AppSubscriptions from '../pages/interim-agency-settings/app-subscription-periods'
import AppSubscription from '../pages/interim-agency-settings/app-subscription-period'
import EmployerSettingsWrapper from '../pages/employer-settings/employer-settings-wrapper'
import ClockingRulesSettings from '../pages/employer-settings/clockings-rules'
import EmployerInfoSettings from '../pages/employer-settings/employer-info'
import EmployerRulesSettings from '../pages/employer-settings/employer-rules'
import PartnersAgenciesSettings from '../pages/employer-settings/partners-agencies'
import WorkPeriodEventsSettings from '../pages/employer-settings/work-period-events'
import JobTitlesSettings from '../pages/employer-settings/job-titles'
import WorkersSettings from '../pages/employer-settings/workers'
import MembersSettings from '../pages/employer-settings/members'
import TerminalsSettings from '../pages/employer-settings/terminals'
import LaborCostsCoefficients from '../pages/employer-settings/labor-costs-coefficients'
import InterimAgencySettingsWrapper from '../pages/interim-agency-settings/interim-agency-settings-wrapper'
import PartnerEmployerDetails from '../pages/interim-agency-settings/partner-employer'
import InterimAgencyWorkersSettings from '../pages/interim-agency-settings/workers'
import InterimAgencyMembersSettings from '../pages/interim-agency-settings/members'
import TerminalsAgenciesSettings from '../pages/interim-agency-settings/terminals'
import { DevForms } from '../pages/dev/forms'
import TerminalsList from '../pages/terminals/list'
import TerminalDetails from '../pages/terminals/details'
import ServicesSettings from '../pages/employer-settings/services'
import { WhatsNewPage } from '../pages/common/whats-new'

const useRoute = (): React.ReactElement | null => {
  return routerUseRoutes([
    //Guest
    {
      path: '/complete-profile',
      element: (
        <Guest>
          <UserCompleteProfile />
        </Guest>
      ),
    },
    {
      path: '/request-new-password',
      element: (
        <Guest>
          <UserRequestNewPassword />
        </Guest>
      ),
    },
    {
      path: '/reset-password',
      element: (
        <Guest>
          <UserResetPassword />
        </Guest>
      ),
    },
    {
      path: '/login',
      element: (
        <Guest>
          <Login />
        </Guest>
      ),
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyPage />,
    },
    {
      path: '/terms-of-use',
      element: <TermsOfUsePage />,
    },
    //Authenticated
    {
      path: '/qr-scanner',
      element: (
        <Authenticated
          roles={['employerMember', 'superAdmin']}
          action={{ resource: 'clockings', name: 'create' }}
        >
          <QrScanner scanner="camera" />
        </Authenticated>
      ),
    },
    {
      path: '/laser-scanner',
      element: (
        <Authenticated
          roles={['employerMember', 'superAdmin']}
          action={{ resource: 'clockings', name: 'create' }}
        >
          <QrScanner scanner="laser" />
        </Authenticated>
      ),
    },
    {
      path: '/kiosk',
      element: (
        <Authenticated
          roles={['employerMember', 'superAdmin']}
          action={{ resource: 'clockings', name: 'create' }}
        >
          <LoginAsQrScannerUserPage />
        </Authenticated>
      ),
    },
    {
      path: '/errors/no-active-role',
      element: <NoActiveRole />,
    },
    {
      path: '/help',
      element: (
        <Authenticated>
          <HelpPage />
        </Authenticated>
      ),
    },
    {
      path: '/whatsnew',
      element: (
        <Authenticated roles={['employerMember', 'interimAgencyMember', 'superAdmin']}>
          <WhatsNewPage />
        </Authenticated>
      ),
    },
    {
      path: '/missions/new',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'missions', name: 'create' }}
          organizationOptions={[{ organizationType: 'employer', option: 'biPart' }]}
        >
          <MissionEdition />
        </Authenticated>
      ),
    },
    {
      path: '/missions/:id/edit',
      element: (
        <Authenticated roles={['employerMember']} action={{ resource: 'missions', name: 'update' }}>
          <MissionEdition />
        </Authenticated>
      ),
    },
    {
      path: '/missions/:id',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'missions', name: 'read' }}
        >
          <MissionDetails />
        </Authenticated>
      ),
    },
    {
      path: '/missions',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'missions', name: 'read' }}
        >
          <MissionsList />
        </Authenticated>
      ),
    },
    {
      path: '/missions/:missionId/work-periods/:workPeriodId',
      element: (
        <Authenticated roles={['employerMember']} action={{ resource: 'missions', name: 'read' }}>
          <WorkPeriodDetails />
        </Authenticated>
      ),
    },
    {
      path: '/work-periods',
      element: (
        <Authenticated roles={['employerMember']} action={{ resource: 'missions', name: 'read' }}>
          <WorkPeriodsList />
        </Authenticated>
      ),
    },
    {
      path: '/agencies',
      element: (
        <Authenticated roles={['superAdmin']}>
          <AgenciesList />
        </Authenticated>
      ),
    },
    {
      path: '/agencies/:id',
      element: (
        <Authenticated roles={['superAdmin']}>
          <InterimAgencyWrapper mode="urlId" />
        </Authenticated>
      ),
      children: [
        { path: 'app-subscription-options', element: <AppSubscriptionOptions /> },
        { path: 'info', element: <AgencyInfoSettings /> },
        { path: 'partner-employers', element: <PartnerEmployers /> },
        { path: 'app-subscription-periods/:appSubscriptionPeriodId', element: <AppSubscription /> },
        { path: 'app-subscription-periods', element: <AppSubscriptions /> },
        { path: 'terminals', element: <TerminalsAgenciesSettings /> },
      ],
    },
    {
      path: '/employers',
      element: (
        <Authenticated roles={['superAdmin']}>
          <EmployersList />
        </Authenticated>
      ),
    },
    {
      path: '/employers/:id',
      element: (
        <Authenticated
          roles={['superAdmin']}
          action={{ resource: 'organizations', name: 'update' }}
        >
          <EmployerDetails />
        </Authenticated>
      ),
    },
    {
      path: '/settings',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'organizations', name: 'update' }}
        >
          <Protected roles={['employerMember']}>
            <EmployerSettingsWrapper />
          </Protected>
          <Protected roles={['interimAgencyMember']}>
            <InterimAgencySettingsWrapper mode={'currentOrganization'} />
          </Protected>
        </Authenticated>
      ),
      children: [
        {
          path: 'info',
          element: (
            <>
              <Protected roles={['employerMember']}>
                <EmployerInfoSettings />
              </Protected>
              <Protected roles={['interimAgencyMember']}>
                <AgencyInfoSettings />
              </Protected>
            </>
          ),
        },
        {
          path: 'clocking-rules/:id',
          element: (
            <Protected roles={['employerMember']}>
              <ClockingRuleDetailsSettings />
            </Protected>
          ),
        },
        {
          path: 'clocking-rules',
          element: (
            <Protected roles={['employerMember']}>
              <ClockingRulesSettings />
            </Protected>
          ),
        },
        {
          path: 'employer-rules',
          element: (
            <Protected roles={['employerMember']}>
              <EmployerRulesSettings />
            </Protected>
          ),
        },
        {
          path: 'partners-agencies',
          element: (
            <Protected roles={['employerMember']}>
              <PartnersAgenciesSettings />
            </Protected>
          ),
        },
        {
          path: 'work-period-events',
          element: (
            <Protected roles={['employerMember']}>
              <WorkPeriodEventsSettings />
            </Protected>
          ),
        },
        {
          path: 'job-titles/:id',
          element: (
            <Protected roles={['employerMember']}>
              <JobTitleDetailsSettings />
            </Protected>
          ),
        },
        {
          path: 'job-titles',
          element: (
            <Protected roles={['employerMember']}>
              <JobTitlesSettings />
            </Protected>
          ),
        },
        {
          path: 'workers',
          element: (
            <>
              <Protected roles={['employerMember']}>
                <WorkersSettings />
              </Protected>
              <Protected roles={['interimAgencyMember']}>
                <InterimAgencyWorkersSettings />
              </Protected>
            </>
          ),
        },
        {
          path: 'members',
          element: (
            <>
              <Protected roles={['employerMember']}>
                <MembersSettings />
              </Protected>
              <Protected roles={['interimAgencyMember']}>
                <InterimAgencyMembersSettings />
              </Protected>
            </>
          ),
        },
        {
          path: 'labor-costs-coefficients',
          element: (
            <Protected roles={['employerMember']}>
              <LaborCostsCoefficients />
            </Protected>
          ),
        },
        {
          path: 'app-subscription-periods/:appSubscriptionPeriodId',
          element: (
            <Protected roles={['interimAgencyMember']}>
              <AppSubscription />
            </Protected>
          ),
        },
        {
          path: 'app-subscription-periods',
          element: (
            <Protected roles={['interimAgencyMember']}>
              <AppSubscriptions />
            </Protected>
          ),
        },
        {
          path: 'partner-employers',
          element: (
            <Protected roles={['interimAgencyMember']}>
              <PartnerEmployers />
            </Protected>
          ),
        },
        {
          path: 'partner-employers/:id',
          element: (
            <Protected roles={['interimAgencyMember']}>
              <PartnerEmployerDetails />
            </Protected>
          ),
        },
        {
          path: 'terminals',
          element: (
            <>
              <Protected roles={['employerMember']}>
                <TerminalsSettings />
              </Protected>
              <Protected roles={['interimAgencyMember']}>
                <TerminalsAgenciesSettings />
              </Protected>
            </>
          ),
        },
        {
          path: 'services',
          element: (
            <Protected roles={['employerMember']}>
              <ServicesSettings />
            </Protected>
          ),
        },
      ],
    },
    {
      path: '/employers/:employerId/rules/:id',
      element: (
        <Authenticated roles={['superAdmin']}>
          <EmployerRulesDetails />
        </Authenticated>
      ),
    },
    {
      path: '/clockings',
      element: (
        <Authenticated roles={['employerMember']} action={{ resource: 'clockings', name: 'read' }}>
          <ClockingsList />
        </Authenticated>
      ),
    },
    {
      path: '/summary',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'missions', name: 'read' }}
        >
          <Summary />
        </Authenticated>
      ),
    },
    {
      path: '/payroll',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'missions', name: 'read' }}
          organizationOptions={[{ organizationType: 'employer', option: 'biPart' }]}
        >
          <PayrollDetails />
        </Authenticated>
      ),
    },
    {
      path: '/super-admins',
      element: (
        <Authenticated roles={['superAdmin']}>
          <UsersList />
        </Authenticated>
      ),
    },
    {
      path: '/me/organizations',
      element: (
        <Authenticated>
          <UserOrganizations />
        </Authenticated>
      ),
    },
    {
      path: '/me',
      element: (
        <Authenticated>
          <UserDetails />
        </Authenticated>
      ),
    },
    {
      path: '/me/notifications',
      element: (
        <Authenticated>
          <UserNotificationsSettings />
        </Authenticated>
      ),
    },
    {
      path: '/print-qr/:userId',
      element: (
        <Authenticated>
          <QrPrintPage />
        </Authenticated>
      ),
    },
    {
      path: '/associations',
      element: (
        <Authenticated roles={['interimAgencyMember']}>
          <AssociationsList />
        </Authenticated>
      ),
    },
    {
      path: '/pre-billings',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'preBillings', name: 'read' }}
        >
          <PreBillingsList />
        </Authenticated>
      ),
    },
    {
      path: '/pre-billings/:id',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'preBillings', name: 'read' }}
        >
          <PreBillingDetails />
        </Authenticated>
      ),
    },
    {
      path: '/workers-requests',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'workersRequests', name: 'read' }}
        >
          <WorkersRequestsList />
        </Authenticated>
      ),
    },
    {
      path: '/workers-requests/new',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'workersRequests', name: 'create' }}
        >
          <WorkersRequestsEdition />
        </Authenticated>
      ),
    },
    {
      path: '/workers-requests/:id/new',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'workersRequests', name: 'create' }}
        >
          <WorkersRequestsEdition />
        </Authenticated>
      ),
    },
    {
      path: '/workers-requests/:id/edit',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'workersRequests', name: 'update' }}
        >
          <WorkersRequestsEdition />
        </Authenticated>
      ),
    },
    {
      path: '/workers-requests/:id',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'workersRequests', name: 'read' }}
        >
          <WorkersRequestsDetails />
        </Authenticated>
      ),
    },
    {
      path: '/',
      element: (
        <Authenticated>
          <Protected roles={['worker']}>
            <WorkerDashboard />
          </Protected>
          <Protected roles={['interimAgencyMember']}>
            <InterimAgencyDashboard />
          </Protected>
          <Protected roles={['superAdmin']}>
            <SuperAdminDashboard />
          </Protected>
          <Protected roles={['employerMember']}>
            <EmployerDashboard />
          </Protected>
        </Authenticated>
      ),
    },
    {
      path: '/kpi',
      element: (
        <Authenticated>
          <Protected roles={['employerMember']}>
            <EmployerKPIsPage />
          </Protected>
        </Authenticated>
      ),
    },
    {
      path: '/admin/billing',
      element: (
        <Authenticated>
          <Protected roles={['superAdmin']}>
            <BillingDashboard />
          </Protected>
        </Authenticated>
      ),
    },
    {
      path: '/dev/forms',
      element: (
        <Authenticated>
          <Protected roles={['superAdmin']}>
            <DevForms />
          </Protected>
        </Authenticated>
      ),
    },
    {
      path: '/app-subscription-periods/:id',
      element: (
        <Authenticated>
          <Protected roles={['superAdmin']}>
            <AppSubscriptionPeriodDetails />
          </Protected>
        </Authenticated>
      ),
    },
    {
      path: '/app-subscription-periods',
      element: (
        <Authenticated roles={[]}>
          <AppSubscriptionPeriodsList />
        </Authenticated>
      ),
    },
    //Common
    { path: '/validate-new-email', element: <UserValidateNewEmail /> },
    {
      path: '/terminals',
      element: (
        <Authenticated roles={['superAdmin']}>
          <TerminalsList />
        </Authenticated>
      ),
    },
    {
      path: '/terminals/:id',
      element: (
        <Authenticated roles={['superAdmin']}>
          <TerminalDetails />
        </Authenticated>
      ),
    },
  ])
}

export default useRoute
