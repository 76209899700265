import React from 'react'
import Table from '../table'
import { Column } from 'react-table'
import { Service } from '../../api/services'
import { sortByIgnoreCase } from '../../utils/arrays'
import { Element } from 'react-bulma-components'

interface ServicesListProps {
  data: Service[]
  itemPath?: string
  onServiceClick: (service: Service) => void
}

const ServicesList: React.FC<ServicesListProps> = ({ data, itemPath, onServiceClick }) => {
  const tableColumns = useListTableColumns(itemPath, onServiceClick)

  return (
    <Table
      columns={tableColumns}
      data={sortByIgnoreCase<Service>(data, 'name')}
      noDataMessage="Aucun Service à afficher"
    />
  )
}

const useListTableColumns = (
  itemPath?: string,
  onServiceClick?: ServicesListProps['onServiceClick'],
): Column<Service>[] => {
  return React.useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'name',
        Cell: data => (
          <Element renderAs="a" color="ghost" onClick={() => onServiceClick?.(data.row.original)}>
            {data.row.original.name}
          </Element>
        ),
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }) => value || '-',
      },
    ],
    [itemPath],
  )
}

export default ServicesList
