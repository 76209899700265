import {
  add,
  addDays,
  addHours,
  addMinutes,
  eachDayOfInterval,
  endOfWeek as fnsEndOfWeek,
  format,
  getWeek as fnsGetWeek,
  setWeek as fnsSetWeek,
  getWeekYear as fsGetWeekYear,
  getYear,
  intervalToDuration,
  isBefore,
  startOfDay,
  startOfWeek as fnsStartOfWeek,
  startOfYear,
  parseISO,
  setDefaultOptions,
  startOfMonth,
  endOfMonth,
  getHours,
  getMinutes,
  formatISO,
  isToday,
  isYesterday,
  isTomorrow,
  isThisWeek,
  subWeeks,
  addWeeks,
} from 'date-fns'
import { fr } from 'date-fns/locale'
import { padStart, round, upperFirst } from 'lodash'
import { IsoDate } from '../hooks/use-calendar'

/**
 * Global date defaults
 */
setDefaultOptions({ locale: fr, weekStartsOn: 1 })

export type WeekDay = 0 | 1 | 2 | 3 | 4 | 5 | 6

export const oneHour = 1000 * 60 * 60

//Convert date to HTML time value format
export const dateToTimeInputValue = (date?: Date): string => {
  if (!date) return ''
  return new Date(date).toLocaleString('fr-FR', {
    hour: 'numeric',
    minute: 'numeric',
  })
}

/**
 * Get a given date set to a given time
 * @param   {Date}                        date
 * @param   {<input type='time'>.value}   time an HTML time string of type 'HH:MM' in 24h format, "" is valid and means input is empty
 * @return  {undefined}                   if time couldn't be parsed
 * @return  {null}                        if time value is an empty input[type=time] default value : ""
 * @return  {Date}                        the given date, set to the given time
 */
export const timeInputValueToDate = (date: Date, time: string): Date | null | undefined => {
  // The HTML time input field is blank
  if (time === '') return null
  // Matching HH:MM in 24h time format with leading zero : https://stackoverflow.com/a/51177696
  const match = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.exec(time)
  // We return undefined if the provided time was not parseable
  if (!match) return
  // We get the hours and minutes from the string and parse them as numbers
  const [hours, minutes] = time.split(':').map(number => Number(number))
  return addMinutes(addHours(startOfDay(new Date(date)), hours), minutes)
}

/**
 * Transform an hour as number (ex: 5.5) to time input value (ex: 05:30)
 * @param hours {Number}
 * @returns {String}
 */

export const hoursAsNumberToTimeInputValue = (hours: number): string => {
  const hoursAsDate = new Date(hours * oneHour)
  return `${new Date(hoursAsDate).getUTCHours().toString().padStart(2, '0')}:${hoursAsDate
    .getUTCMinutes()
    .toString()
    .padStart(2, '0')}`
}

/**
 * Transform input type value (ex: 05:30) to hours as number (ex: 5.5)
 * @param inputValue
 * @returns {Number | undefined}
 */

export const timeInputValueToHoursAsNumber = (inputValue: string): number | undefined => {
  const match = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/.exec(inputValue)
  if (!match) return
  return round(Number(match[1]) + Number(match[2]) / 60, 2)
}

/**
 * Takes a date, and from/to time, and returns a 24h date interval set to from/to time using @see timeInputValueToDate
 * If @fromDate couldn't be computed it still computes the 24h interval using @date
 * @example ('2023-03-16T00:00:00.000Z', '23:00', '05:00') => ['2023-03-16T23:00:00.000Z', ''2023-03-17T05:00:00.000Z'']
 * @example ('2023-03-16T00:00:00.000Z', '', '05:00') => [null, ''2023-03-17T05:00:00.000Z'']
 * @param   {Date}                      date a Date Object
 * @param   {<input type='time'>.value} fromTime an HTML time string of type 'HH:MM' in 24h format, "" is valid and means input is empty
 * @param   {<input type='time'>.value} toTime an HTML time string of type 'HH:MM' in 24h format, "" is valid and means input is empty
 * @return  {[fromDate, toDate]} if @fromDate is set with @toDate - @fromDate < 24h00
 */
export const fromToTimeInputsValueToDates = (
  date: Date,
  fromTime: string,
  toTime: string,
): [Date | null | undefined, Date | null | undefined] => {
  const fromDate = timeInputValueToDate(date, fromTime)
  const toDate = timeInputValueToDate(date, toTime)
  const baseDate = new Date(date)

  // Handling cases where toTime is the next day of fromTime, e.g. past midnight
  // If no fromDate could be computed, we still evaluate toDate against the original date provided
  if (!fromDate && toDate && isBefore(toDate, baseDate)) return [fromDate, addDays(toDate, 1)]
  if (fromDate && toDate && toDate < fromDate) return [fromDate, addDays(toDate, 1)]

  return [fromDate, toDate]
}

//Return week from to label : Semaine x - du y au z
const buildWeekLabel = (date: Date): string =>
  `Semaine ${getWeek(date)} - du 
  ${format(startOfWeek(date), 'dd/MM/yyyy')} au 
  ${format(endOfWeek(date), 'dd/MM/yyyy')}`

// Convert date to HTML week value format
const dateToWeekInputValue = (date: Date): string => `${getYear(date)}-W${getWeek(date)}`

// Convert value returned by HTML week input (ex: 2021-W20) intos start and end dates
const weekInputValueToDates = (value?: string): { start: Date; end: Date } | undefined => {
  if (!value) value = dateToWeekInputValue(new Date())
  const match = /([0-9]*)-W([0-9]*)/.exec(value)
  if (!match) return

  const startWeekDate = startOfWeek(
    add(startOfYear(new Date(Number(match[1]), 0, 1)), {
      weeks: Number(match[2]),
    }),
  )
  return {
    start: startWeekDate,
    end: new Date(add(startWeekDate, { weeks: 1 }).getTime() - 1),
  }
}

// Convert value returned by HTML date input (ex: 2021-12-15) into start and end dates
export const dateInputValuesToWeekDates = (value?: IsoDate): { start: Date; end: Date } => {
  const date = value ? new Date(value) : new Date()
  return {
    start: startOfWeek(date),
    end: endOfWeek(date),
  }
}

const dateInputValuesToMonthDates = (value?: string): { start: Date; end: Date } => {
  const date = value ? new Date(value) : new Date()
  return {
    start: startOfMonth(date),
    end: endOfMonth(date),
  }
}

export const durationToTimeInputValue = (duration: number): string =>
  `${getHours(duration) - 1}:${getMinutes(duration)}`

export const timeInputValueToDuration = (inputValue: string): number => {
  const match = /([0-9][0-9]):([0-9][0-9])/.exec(inputValue)
  if (!match) throw 'Invalid time input value'
  return Number(match[1]) * 60 * 60 * 1000 + Number(match[2]) * 60 * 1000
}

// Convert date to HTML date input value (ex: 2021-12-15)
export const dateToDateInputValue = (date?: Date): IsoDate => {
  if (!date) date = new Date()
  return formatISODate(date)
}

// Convert HTML date input value (ex: 2021-12-15) date to date object
export const dateInputToDateValue = (date: string): Date => {
  return parseISO(date)
}

/* date-fns wrappers */
type DateParam = Date | string | number
const buildDate = (date?: DateParam): Date => {
  if (!date) return new Date()
  else if (date instanceof Date) return date
  return new Date(date)
}

// Get week number of a date. If no date is passed, the date treated is today
export const getWeek = (date?: DateParam): number => fnsGetWeek(buildDate(date), { locale: fr })

export const getWeekYear = (date: Date): number => fsGetWeekYear(date, { locale: fr })

// Get week number of a date. If no date is passed, the date treated is today
export const setWeek = (date: DateParam, week: number): Date =>
  fnsSetWeek(buildDate(date), week, { locale: fr })

// Get start of week
export const startOfWeek = (date?: DateParam): Date =>
  fnsStartOfWeek(buildDate(date), { weekStartsOn: 1 })

// Get end of week
export const endOfWeek = (date?: DateParam): Date =>
  fnsEndOfWeek(buildDate(date), { weekStartsOn: 1 })

// Corrects the weekday from sunday-based to monday-based
export const getWeekdayMondayBased = (weekdaySundayBased: WeekDay): WeekDay =>
  // If weekDay is 0, we are sunday !
  weekdaySundayBased === 0 ? 6 : ((weekdaySundayBased - 1) as WeekDay)

//Formats

//Ex: mercredi 01 décembre 2021 12:00
export const formatCompleteDateTime = (date?: DateParam): string =>
  date ? format(buildDate(date), "EEEE dd MMMM yyyy H'h'mm", { locale: fr }) : ''

//Ex: mercredi 01 décembre 12:00
export const formatShortDateTime = (date?: DateParam): string =>
  date ? format(buildDate(date), 'dd/MM/yy H:mm:ss', { locale: fr }) : ''

//Ex: mercredi 01 décembre 2021
export const formatCompleteDate = (date?: DateParam): string =>
  date ? format(buildDate(date), 'EEEE dd MMMM yyyy', { locale: fr }) : ''

//Ex: mercredi 01 décembre 12:00
const formatShortDate = (date?: DateParam): string =>
  date ? format(buildDate(date), 'dd/MM/yy', { locale: fr }) : ''

export const formatTime = (date?: DateParam): string =>
  date ? format(buildDate(date), "HH'h'mm", { locale: fr }) : ''

export const localDate = (date?: DateParam): string =>
  new Date(buildDate(date)).toLocaleDateString()

export const formatDate = (date?: DateParam): string =>
  date ? format(buildDate(date), 'dd/MM/yyyy') : ''

export const formatDateWithTime = (date?: DateParam): string =>
  date ? format(buildDate(date), 'dd/MM/yyyy HH:mm', { locale: fr }) : ''

export const formatDuration = (timestamp: number): string => {
  // Convertir les millisecondes en heures et minutes
  const totalMinutes = Math.floor(timestamp / (1000 * 60))
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  return `${padStart(hours.toString(), 2, '0')}h${padStart(minutes.toString(), 2, '0')}`
}

export const formatDay = (date?: DateParam): string =>
  date ? upperFirst(format(buildDate(date), 'E d', { locale: fr })) : ''

export const formatDayName = (date?: DateParam): string =>
  date ? upperFirst(format(buildDate(date), 'EEEE', { locale: fr })) : ''

//formatInTimeZone(new Date(duration), 'UTC', "HH'h'mm")

export const allDaysOfInterval = (start: DateParam, end: DateParam): Date[] => {
  return eachDayOfInterval({ start: buildDate(start), end: buildDate(end) })
}

export const allDaysOfCurrentWeek = allDaysOfInterval(
  startOfWeek(new Date()),
  endOfWeek(new Date()),
)

//Week days as input options
export const weekDaysAsInputOptions = (days: Date[]): Partial<HTMLOptionElement>[] => [
  { value: '', label: 'Toute la semaine', defaultSelected: true },
  ...days.map(day => ({
    value: day.toString(),
    label: formatDay(day),
  })),
]

export const convertDateToUTCKeepingTime = (date: Date): Date =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
  )

export const formatUtcDate = (isoString: string): string => {
  const datePart = isoString.split('T')[0]
  return datePart ? format(parseISO(datePart), 'dd/MM/yyyy', { locale: fr }) : ''
}

/**
 * @param {Date} date a Date object
 * @returns {string} a string of the format YYYY-MM-DD compliant with ISO8601
 */
export const formatISODate = (date: Date): IsoDate => {
  return formatISO(date, { representation: 'date' }) as IsoDate
}

export const dateRangeDisplayHelper = (start: IsoDate, end: IsoDate, contextual = true): string => {
  const startDate = parseISO(start)
  const endDate = parseISO(end)

  try {
    const dateRangeIsDay = eachDayOfInterval({ start: startDate, end: endDate }).length === 1

    if (contextual) {
      const dataRangeTitle = dateRangeIsDay
        ? isToday(startDate)
          ? "Aujourd'hui"
          : isYesterday(startDate)
          ? 'Hier'
          : isTomorrow(startDate)
          ? 'Demain'
          : formatCompleteDate(startDate)
        : isThisWeek(startDate) && isThisWeek(endDate)
        ? 'Cette Semaine'
        : isThisWeek(subWeeks(startDate, 1)) && isThisWeek(subWeeks(endDate, 1))
        ? 'Semaine Prochaine'
        : isThisWeek(addWeeks(startDate, 1)) && isThisWeek(addWeeks(endDate, 1))
        ? 'Semaine Passée'
        : `Du ${formatCompleteDate(startDate)} au ${formatCompleteDate(endDate)}`

      return dataRangeTitle
    } else {
      const dateIs = dateRangeIsDay
        ? formatCompleteDate(start)
        : `Du ${formatCompleteDate(startDate)} au ${formatCompleteDate(endDate)}`
      return dateIs
    }
  } catch (err) {
    // Bad date range interval
    return 'Intervalle de dates invalide'
  }
}

/**
 * Converts a timestamp duration to decimal hours
 * @example 61200000 (17h00) => 17
 * @example 61500000 (17h05) => 17.08
 * @param timestamp - Duration in milliseconds
 * @returns number - Hours in decimal format with 2 decimal places
 */
export const formatDurationAsDecimal = (timestamp: number): string => {
  const totalMinutes = Math.floor(timestamp / (1000 * 60))
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  return Number((hours + minutes / 60).toFixed(2)).toString()
}
