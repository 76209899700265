import React from 'react'
import {
  useCreateMutation,
  useUpdateUserOrganizationRoleMutation,
  useUpdateWorkerMutation,
} from '../../queries/users'
import useStore from '../../store'
import { UpdateWorkerPayload, User, UserRoleKind } from '../../api/users'
import { Button, Container, Element, Heading, Message } from 'react-bulma-components'
import { Field, FieldArray, Form, Formik } from 'formik'
import InputField from '../form/fields/input'
import { OrganizationTypeEnum } from '../../api/organizations'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import { getUserRole, getWorkerAndOrganizationAssociation } from '../../utils/users'
import { CreateUserParams } from '../../../../backend/src/services/resources/users/users.controller.service.types'
import SelectField from '../form/fields/select'
import usersLocales from '../../../../locales/users'
import Modal, { useModal } from '../modal'
import { MassImport } from '../../pages/users/mass-import'
import Protected, { isAuthorizedFor } from '../protected/protected'
import ComponentFooter from '../sections/component-footer'
import { UserEmployerMemberRole } from '../../../../backend/src/services/resources/users/user.model'
import WorkerLabel from '../texts/worker-label'
import { useOrganizationsQuery } from '../../queries/organizations'
import CheckboxField from '../form/fields/checkbox'

interface UserFormProps {
  user?: User
  role: UserRoleKind
  noAppAccess?: boolean
}

interface UserFormValue {
  email: string
  firstName: string
  lastName: string
  internalRef?: string
  internalInformation?: string
  internalComment?: string
  organizationRole?: 'admin' | UserEmployerMemberRole
  schedule: {
    weeklyHoursWorked: number
    monthlyHoursWorked: number
  }
  laborCostCoefficients?: {
    [key: string]: { isManagement: boolean }
  }
}

export type InterimAgencyWorkerFormValue = Pick<
  UserFormValue,
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'internalRef'
  | 'internalComment'
  | 'internalInformation'
  | 'laborCostCoefficients'
>
export type EmployerWorkerFormValue = Pick<
  UserFormValue,
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'internalRef'
  | 'schedule'
  | 'internalComment'
  | 'internalInformation'
>

interface UserFormValues {
  newUsers: Array<UserFormValue>
}

interface UserFormErrors {
  newUsers: {
    [key: number]: Partial<Record<keyof UserFormValues['newUsers'][number], string>>
  }
}

const UserForm: React.FC<UserFormProps> = ({ user, role, noAppAccess }) => {
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateWorkerMutation()
  const updateUserOrganizationRole = useUpdateUserOrganizationRoleMutation()
  const currentOrganization = useStore(state => state.session.currentOrganization)
  const userAssociation =
    user && currentOrganization && getWorkerAndOrganizationAssociation(user, currentOrganization)
  const userRole =
    user && currentOrganization && role === 'employerMember'
      ? getUserRole(user, role, currentOrganization._id)
      : undefined
  const importCsvModal = useModal({})

  const associatedEmployerQuery = useOrganizationsQuery(
    { type: 'employer' },
    { enabled: isAuthorizedFor(['interimAgencyMember']) },
  )

  /* TODO: Review this in v5*/
  const description = useDescription(role)

  const initalLaborCostCoefficients: UserFormValue['laborCostCoefficients'] =
    userAssociation?.laborCostCoefficients.reduce<UserFormValue['laborCostCoefficients']>(
      (formValue, coef) => {
        if (!formValue) formValue = {}
        formValue[coef.employer.toString()] = {
          isManagement: coef.kind === 'management',
        }
        return formValue
      },
      {},
    )

  const emailCanBeUpdated =
    (!user || (user && !user.isCompleted && !user.associations[1])) && !noAppAccess

  return (
    <Formik<UserFormValues>
      initialValues={{
        newUsers: [
          {
            email: user?.email && emailCanBeUpdated ? user?.email : '',
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            internalRef: userAssociation?.internalRef,
            internalInformation: userAssociation?.internalInformation,
            internalComment: userAssociation?.internalComment,
            organizationRole: userRole && userRole.organizationRole,
            schedule: {
              weeklyHoursWorked: userAssociation?.schedule.weeklyHoursWorked || 0,
              monthlyHoursWorked: userAssociation?.schedule.monthlyHoursWorked || 0,
            },
            laborCostCoefficients: initalLaborCostCoefficients,
          },
        ],
      }}
      enableReinitialize
      validate={values => {
        const errors: UserFormErrors = {
          newUsers: {},
        }
        let index = 0
        for (const newUser of values.newUsers) {
          if (emailCanBeUpdated && !newUser.email) {
            errors.newUsers[index] = { ...errors.newUsers[index], email: 'Champ requis' }
          }
          if (role === 'worker' && !user) {
            if (!newUser.firstName) {
              errors.newUsers[index] = {
                ...errors.newUsers[index],
                firstName: 'Champ requis',
              }
            }
            if (!newUser.lastName) {
              errors.newUsers[index] = { ...errors.newUsers[index], lastName: 'Champ requis' }
            }
          } else if (role === 'worker' && user) {
            if (!newUser.internalRef) {
              errors.newUsers[index] = {
                ...errors.newUsers[index],
                internalRef: 'Champ requis',
              }
            }
          }
          if (role === 'employerMember') {
            if (!newUser.organizationRole) {
              errors.newUsers[index] = {
                ...errors.newUsers[index],
                organizationRole: 'Champ requis',
              }
            }
          }
          index++
        }
        if (Object.keys(errors.newUsers).length > 0) {
          return errors
        }
        return {}
      }}
      onSubmit={async values => {
        const { newUsers } = values
        for (const newUser of newUsers) {
          const {
            email,
            firstName,
            lastName,
            schedule,
            internalRef,
            internalComment,
            internalInformation,
            organizationRole,
            laborCostCoefficients,
          } = newUser

          if (!user) {
            const payload: CreateUserParams = {
              firstName,
              lastName,
              role: { kind: role },
              appAccess: noAppAccess ? 'none' : 'full',
            }
            if (role === 'worker' && currentOrganization) {
              payload.association = currentOrganization._id
              payload.internalRef = internalRef
              payload.internalComment = internalComment
              payload.internalInformation = internalInformation
              if (currentOrganization.type === OrganizationTypeEnum.employer)
                payload.schedule = schedule
            }
            if (!noAppAccess) payload.email = email
            if (
              (role === 'interimAgencyMember' || role === 'employerMember') &&
              currentOrganization
            ) {
              payload.role.organization = currentOrganization._id
              payload.role.organizationRole = organizationRole || 'admin'
            }

            await createMutation.mutateAsync(payload).then(
              res =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve(res)
                  }, 250)
                }),
            )
          } else if (role === 'employerMember') {
            organizationRole &&
              updateUserOrganizationRole.mutate({
                userId: user._id,
                organizationRole,
              })
          } else {
            const payload: UpdateWorkerPayload = {
              id: user._id,
            }
            if (role === 'worker') {
              payload.internalRef = internalRef
              payload.internalComment = internalComment
              payload.internalInformation = internalInformation
              payload.firstName = firstName
              payload.lastName = lastName
              if (emailCanBeUpdated) payload.email = email
              if (
                currentOrganization?.type === OrganizationTypeEnum.interimAgency &&
                laborCostCoefficients
              ) {
                payload.laborCostCoefficients = Object.keys(laborCostCoefficients).map(
                  employerId => ({
                    employer: employerId,
                    kind: laborCostCoefficients[employerId]?.isManagement
                      ? 'management'
                      : 'delegation',
                  }),
                )
              }
            }
            if (currentOrganization?.type === OrganizationTypeEnum.employer)
              payload.schedule = schedule

            updateMutation.mutate(payload)
          }
        }
      }}
    >
      {({ values, setFieldValue, isSubmitting, submitCount }) => {
        return (
          <Form>
            <Container>
              <Element mb={5}>{!user && !noAppAccess && description}</Element>
              <Modal
                title="Importer depuis un fichier CSV"
                actions={importCsvModal}
                maxWidth={1080}
              >
                <MassImport
                  onSuccess={newUsers => {
                    setFieldValue('newUsers', newUsers)
                    importCsvModal.setIsDisplayed(false)
                  }}
                  model={
                    currentOrganization?.type === 'interimAgency'
                      ? [
                          'email',
                          'firstName',
                          'lastName',
                          'internalRef',
                          'internalComment',
                          'internalInformation',
                        ]
                      : [
                          'email',
                          'firstName',
                          'lastName',
                          'internalRef',
                          'schedule.weeklyHoursWorked',
                          'schedule.monthlyHoursWorked',
                          'internalComment',
                          'internalInformation',
                        ]
                  }
                />
              </Modal>
              {isSubmitting && (
                <Modal
                  title="Invitation en cours..."
                  actions={{
                    isDisplayed: true,
                    setIsDisplayed: () => {
                      return
                    },
                  }}
                >
                  Invitation en cours : {createMutation.data?.data.email}
                </Modal>
              )}
              {values.newUsers.length > 1 ? (
                <Message color="info">
                  <Message.Header>
                    {values.newUsers.length} invitations sont prêtes à être envoyées !
                  </Message.Header>
                  <Message.Body>Ne fermez pas votre navigateur durant l'opération</Message.Body>
                </Message>
              ) : (
                <FieldArray name="newUsers">
                  {({ move, swap, push, insert, unshift, pop, form }) => {
                    return values.newUsers.map((newUser, index) => {
                      return (
                        <Element key={index}>
                          {emailCanBeUpdated && (
                            <Field
                              name={`newUsers.${index}.email`}
                              label="Email"
                              type="email"
                              component={InputField}
                              required
                              placeholder="adresse@email.com"
                            />
                          )}
                          {role === 'employerMember' && (
                            <Field
                              label="Rôle"
                              name={`newUsers.${index}.organizationRole`}
                              component={SelectField}
                              options={[
                                { value: 'admin', label: usersLocales.organizationRoles['admin'] },
                                {
                                  value: 'teamLeader',
                                  label: usersLocales.organizationRoles['teamLeader'],
                                },
                                {
                                  value: 'clocker',
                                  label: usersLocales.organizationRoles['clocker'],
                                },
                              ]}
                            />
                          )}
                          {role === 'worker' && (
                            <>
                              {
                                <>
                                  <Field
                                    label="Prénom"
                                    name={`newUsers.${index}.firstName`}
                                    component={InputField}
                                    required
                                    placeholder="Prénom..."
                                  />
                                  <Field
                                    label="Nom de Naissance"
                                    name={`newUsers.${index}.lastName`}
                                    component={InputField}
                                    required
                                    placeholder="Nom..."
                                  />
                                </>
                              }
                              <Field
                                label="Matricule Interne"
                                name={`newUsers.${index}.internalRef`}
                                component={InputField}
                                help={
                                  <>
                                    Uniquement visible par les collaborateurs de{' '}
                                    <b>{currentOrganization?.name}</b>.
                                    {!user && (
                                      <>
                                        <br />
                                        Un matricule sera généré automatiquement si ce champ n'est
                                        pas rempli.
                                      </>
                                    )}
                                  </>
                                }
                              />
                              <Field
                                label="Information Interne"
                                name={`newUsers.${index}.internalInformation`}
                                component={InputField}
                                help={
                                  <>
                                    Uniquement visible par les collaborateurs de{' '}
                                    <b>{currentOrganization?.name}</b>.
                                  </>
                                }
                              />
                              <Field
                                label="Commentaire Interne"
                                name={`newUsers.${index}.internalComment`}
                                component={InputField}
                                help={
                                  <>
                                    Uniquement visible par les collaborateurs de{' '}
                                    <b>{currentOrganization?.name}</b>.
                                  </>
                                }
                              />
                              {currentOrganization?.type === OrganizationTypeEnum.employer && (
                                <>
                                  <Field
                                    label="Nombre d'heures à prester par semaine"
                                    name={`newUsers.${index}.schedule.weeklyHoursWorked`}
                                    component={InputField}
                                    required
                                    type="number"
                                  />
                                  <Field
                                    label="Nombre d'heures à prester par mois (mensualisation)"
                                    name={`newUsers.${index}.schedule.monthlyHoursWorked`}
                                    component={InputField}
                                    required
                                    type="number"
                                  />
                                </>
                              )}
                              {user && (
                                <Protected roles={['interimAgencyMember']}>
                                  <>
                                    <Heading size={5} mb={1} mt={5}>
                                      Gestion / Délégation
                                    </Heading>
                                    <Element textSize={7} mb={4}>
                                      Si "gestion" est sélectionné pour le travailleur et
                                      l'Entreprises Utilisatrices, le coefficient gestion de
                                      l'intitulé de poste sera pris en compte pour le calcul du côut
                                      horaire. Sinon, le coefficient délégation sera utilisé.
                                    </Element>
                                    {(associatedEmployerQuery.data || []).map(employer => (
                                      <Element key={employer._id}>
                                        <Field
                                          component={CheckboxField}
                                          label={employer.name}
                                          text={'Gestion'}
                                          name={`newUsers.${index}.laborCostCoefficients[${employer._id}].isManagement`}
                                        ></Field>
                                      </Element>
                                    ))}
                                  </>
                                </Protected>
                              )}
                            </>
                          )}
                        </Element>
                      )
                    })
                  }}
                </FieldArray>
              )}
              <ComponentFooter>
                <ComponentFooter.Left>
                  <Protected roles={['interimAgencyMember', 'employerMember']}>
                    {!noAppAccess && !user && role === 'worker' && (
                      <Button
                        onClick={() => importCsvModal.setIsDisplayed(true)}
                        color="primary"
                        outlined
                        data-test="import-from-csv-action"
                      >
                        Import depuis un fichier CSV
                      </Button>
                    )}
                  </Protected>
                </ComponentFooter.Left>
                <ComponentFooter.Right>
                  <RequestButton
                    color="primary"
                    type="submit"
                    data-test="submit-invite-user"
                    mutation={
                      user
                        ? role === 'employerMember'
                          ? updateUserOrganizationRole
                          : updateMutation
                        : createMutation
                    }
                  >
                    {user
                      ? 'Mettre à jour'
                      : noAppAccess
                      ? 'Créer un utilisateur Offline'
                      : 'Inviter'}
                  </RequestButton>
                  <RequestMessage mutation={user ? updateMutation : createMutation} />
                </ComponentFooter.Right>
              </ComponentFooter>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UserForm

const useDescription = (role: UserFormProps['role']) =>
  React.useMemo(() => {
    switch (role) {
      case 'worker':
        return (
          <>
            Invitez un Nouveau <WorkerLabel /> à collaborer avec votre Agence.
            <br />
            <br />
            Il devra accepter votre invitation en se connectant :
            <Element className="content" mb={5}>
              <ul>
                <li>Soit via l'app mobile TeamTim (compatible Android et iOS)</li>
                <li>Soit via la plateforme web TeamTim</li>
              </ul>
            </Element>
            <Element mb={3}>
              S'il s'agit de la première fois qu'il utilise TeamTim, il devra compléter son profil
              avant d'accepter l'invitation.
            </Element>
          </>
        )
      case 'interimAgencyMember':
      case 'employerMember':
        return "Inviter un Nouvel Utilisateur. Cette personne pourra gérer l'agence au même titre que vous."
      default:
        return 'Inviter un Nouveau Super Administrateur de la plateforme. Cette personne aura les mêmes droits que vous.'
    }
  }, [role])
