import React, { useEffect } from 'react'
import { Button, Element, Message, Modal } from 'react-bulma-components'
import { useAppNeedsUpdate } from '../../hooks/use-app-needs-update'
import { useLocation } from 'react-router-dom'
import { CountBack } from '../../hooks/use-count-back'

const autoUpdateTimeout = 5000

export const AppNeedsUpdateBanner: React.FC = () => {
  const { appNeedsUpdate } = useAppNeedsUpdate()
  const { pathname } = useLocation()
  const isScannerOrKioskPage =
    pathname.includes('/laser-scanner') || pathname.includes('/qr-scanner')

  useEffect(() => {
    if (!appNeedsUpdate) return
    if (!isScannerOrKioskPage) return
    // We are on a scanner/kiosk page, we want scanners/kiosks to be always up to date
    // Without even even asking user confirmation

    const timeout = setTimeout(forceHardRefresh, autoUpdateTimeout)
    return () => {
      clearTimeout(timeout)
    }
  }, [appNeedsUpdate, isScannerOrKioskPage])

  if (!appNeedsUpdate) return

  return (
    <Modal closeOnBlur={false} closeOnEsc={false} show={true} showClose={false}>
      <Modal.Content>
        <Message color={'warning'} backgroundColor="warning-light">
          <Message.Header>Nouvelle version disponible</Message.Header>
          <Message.Body m={0} p={3}>
            <Element mb={3}>Une nouvelle version de la plateforme est disponible.</Element>
            <Button color={'warning'} onClick={forceHardRefresh}>
              {isScannerOrKioskPage ? (
                <>
                  Mise à jour automatique dans <CountBack seconds={autoUpdateTimeout / 1000} />{' '}
                  secondes
                </>
              ) : (
                <>Appliquer la mise à jour</>
              )}
            </Button>
          </Message.Body>
        </Message>
      </Modal.Content>
    </Modal>
  )
}

const forceHardRefresh = (): void => {
  // We first fetch the url in the background by explicitly not caching it in headers
  // Then do a "hard reload"
  // This is a combination of 2 methods talked in
  // https://stackoverflow.com/questions/2099201/javascript-hard-refresh-of-current-page
  const url = new URL(window.location.href)
  fetch(url, {
    headers: {
      Pragma: 'no-cache',
      Expires: '-1',
      'Cache-Control': 'no-cache',
    },
  })
    // @ts-expect-error
    // See https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
    // Firefox accepts a forceReload boolean parameter
    // Other browsers will just discard the parameter
    .then(ok => window.location.reload(true))
    .catch(err => {
      console.error('Unexpected error when trying to prefetch before force hard refresh', err)
      // Refresh anyway
      // @ts-expect-error, see above
      window.location.reload(true)
    })
}
