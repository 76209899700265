import React from 'react'
import { Form, Button, Columns, Icon, Heading, Box } from 'react-bulma-components'
import { Formik, Form as FormikForm, Field, FieldArray } from 'formik'
import { useCreateMutation, useUpdateMutation } from '../../queries/terminals'
import { Terminal, TerminalCreationPayload } from '../../api/terminals'
import { TerminalStatusEnum } from '../../../../backend/src/services/resources/terminals/terminal.types'
import InputField from '../form/fields/input'
import SelectField from '../form/fields/select'
import { RequestButton } from '../request-components/request-components'
import { RemoveIcon } from '../icons'
import { dateToDateInputValue } from '../../utils/date'
import { useOrganizationsQuery } from '../../queries/organizations'
import { buildListAsInputOptions } from '../../utils/forms'
import { Employer, InterimAgency } from '../../api/organizations'
import { compareDocsById } from '../../utils/documents'

interface TerminalFormProps {
  terminal?: Terminal
  onSuccess?: () => void
}

const initialValues: TerminalCreationPayload = {
  device: {
    name: '',
    serialNumber: '',
  },
  status: TerminalStatusEnum.inactive,
  usagePeriods: [
    {
      startDate: new Date(),
      employer: '',
      interimAgency: '',
      rentalCost: 0,
    },
  ],
}

const TerminalForm: React.FC<TerminalFormProps> = ({ terminal, onSuccess }) => {
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()
  const agenciesQuery = useOrganizationsQuery<InterimAgency>({ type: 'interimAgency' })
  const employersQuery = useOrganizationsQuery<Employer>({ type: 'employer' })

  // Get associated employers for the selected agency
  const getAssociatedEmployers = (interimAgencyId: string | undefined) => {
    if (!employersQuery.data || !interimAgencyId) return []
    return employersQuery.data.filter(employer =>
      employer.associations.some(
        a => a.status === 'accepted' && compareDocsById(a.interimAgency, interimAgencyId),
      ),
    )
  }

  const validate = (values: TerminalCreationPayload) => {
    const errors: Record<string, any> = {}

    if (!values.device?.name) {
      errors.device = errors.device || {}
      errors.device.name = "Le nom de l'appareil est requis"
    }

    if (!values.device?.serialNumber) {
      errors.device = errors.device || {}
      errors.device.serialNumber = 'Le numéro de série est requis'
    } else if (!/^[A-Za-z0-9-]+$/.test(values.device.serialNumber)) {
      errors.device = errors.device || {}
      errors.device.serialNumber = 'Format invalide (lettres, chiffres et tirets uniquement)'
    }

    // Usage periods validation
    if (!values.usagePeriods) {
      values.usagePeriods = []
    }

    // Sort periods by start date to make validation easier
    const sortedPeriods = [...values.usagePeriods].sort(
      (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
    )

    // Check overlaps and other validations
    sortedPeriods.forEach((period, index) => {
      const currentIndex = (values.usagePeriods || []).indexOf(period)

      if (!period.startDate) {
        errors[`usagePeriods.${currentIndex}.startDate`] = 'Date de début requise'
      }

      if (!period.employer) {
        errors[`usagePeriods.${currentIndex}.employer`] = 'Employeur requis'
      }

      if (!period.interimAgency) {
        errors[`usagePeriods.${currentIndex}.interimAgency`] = "Agence d'intérim requise"
      }

      if (typeof period.rentalCost !== 'number' || period.rentalCost < 0) {
        errors[`usagePeriods.${currentIndex}.rentalCost`] = 'Cout de location requis'
      }

      // Check if end date is after start date
      if (
        period.endDate &&
        period.startDate &&
        new Date(period.endDate) < new Date(period.startDate)
      ) {
        errors[`usagePeriods.${currentIndex}.endDate`] =
          'La date de fin doit être après la date de début'
      }

      // Check overlapping periods have the same employer
      sortedPeriods.forEach((otherPeriod, otherIndex) => {
        if (index !== otherIndex) {
          const currentStart = new Date(period.startDate)
          const currentEnd = period.endDate ? new Date(period.endDate) : new Date('9999-12-31')
          const otherStart = new Date(otherPeriod.startDate)
          const otherEnd = otherPeriod.endDate
            ? new Date(otherPeriod.endDate)
            : new Date('9999-12-31')

          // Check if periods overlap
          if (
            currentStart <= otherEnd &&
            currentEnd >= otherStart &&
            period.employer !== otherPeriod.employer
          ) {
            const originalIndex = (values.usagePeriods || []).indexOf(period)
            errors[`usagePeriods.${originalIndex}.employer`] =
              'Les périodes qui se chevauchent doivent avoir le même employeur'
          }

          // Check if periods with same employer and agency overlap
          if (
            currentStart <= otherEnd &&
            currentEnd >= otherStart &&
            period.employer === otherPeriod.employer &&
            period.interimAgency === otherPeriod.interimAgency
          ) {
            const originalIndex = (values.usagePeriods || []).indexOf(period)
            errors[`usagePeriods.${originalIndex}.startDate`] =
              'Les périodes pour le même employeur et la même agence ne peuvent pas se chevaucher'
          }
        }
      })
    })

    return errors
  }

  const handleSubmit = async (values: TerminalCreationPayload) => {
    // Ensure endDate is undefined if empty string (input returns empty string if empty and api expects undefined)
    if (values.usagePeriods) {
      values.usagePeriods = values.usagePeriods.map(element => ({
        ...element,
        endDate: element.endDate || undefined,
      }))
    }

    try {
      if (terminal) {
        await updateMutation.mutateAsync({
          ...values,
          id: terminal._id,
        })
      } else {
        await createMutation.mutateAsync({
          ...values,
        })
      }
      onSuccess?.()
    } catch (error) {
      console.error('Erreur lors de la création du terminal:', error)
    }
  }

  return (
    <Formik
      //@ts-ignore
      initialValues={
        terminal
          ? {
              ...terminal,
              usagePeriods: terminal.usagePeriods.map(usagePeriod => ({
                ...usagePeriod,
                startDate: dateToDateInputValue(new Date(usagePeriod.startDate)),
                endDate: usagePeriod.endDate
                  ? dateToDateInputValue(new Date(usagePeriod.endDate))
                  : undefined,
                employer: (usagePeriod.employer as Employer)._id,
                interimAgency: (usagePeriod.interimAgency as InterimAgency)._id,
              })),
            }
          : initialValues
      }
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <FormikForm>
          <Field
            label="Nom de l'appareil"
            name="device.name"
            component={InputField}
            placeholder="NewLand Nquire 500..."
            required
          />

          <Field
            label="Numéro de série"
            name="device.serialNumber"
            component={InputField}
            placeholder="TERM-001-XYZ..."
            required
          />

          {/*<Field
            label="Statut"
            name="status"
            component={SelectField}
            options={[
              { value: TerminalStatusEnum.active, label: 'Actif' },
              { value: TerminalStatusEnum.inactive, label: 'Inactif' },
            ]}
            required
          /> */}

          <Heading size={5} mt={5}>
            Périodes d'utilisation
            <p className="help mb-4">
              Les périodes d'utilisation peuvent se chevaucher. Une période sans date de fin est
              considérée comme active.
            </p>
          </Heading>

          <FieldArray name="usagePeriods">
            {({ push, remove }) => (
              <div>
                {values.usagePeriods?.map((_, index) => (
                  <Box key={index} style={{ position: 'relative' }}>
                    {values.usagePeriods && (
                      <Button
                        color="danger"
                        size="small"
                        onClick={() => remove(index)}
                        style={{
                          position: 'absolute',
                          top: '1rem',
                          right: '1rem',
                        }}
                      >
                        <Icon>
                          <RemoveIcon color="white" />
                        </Icon>
                      </Button>
                    )}
                    <Columns mt={2}>
                      <Columns.Column size={4}>
                        <Field
                          label="Date de début"
                          name={`usagePeriods.${index}.startDate`}
                          component={InputField}
                          type="date"
                          required
                        />
                      </Columns.Column>
                      <Columns.Column size={4}>
                        <Field
                          label="Date de fin"
                          name={`usagePeriods.${index}.endDate`}
                          component={InputField}
                          min={values.usagePeriods?.[index]?.startDate}
                          type="date"
                        />
                      </Columns.Column>

                      <Columns.Column size={4}>
                        <Field
                          label="Coût de location"
                          name={`usagePeriods.${index}.rentalCost`}
                          component={InputField}
                          type="number"
                          min="0"
                          step="0.01"
                          required
                        />
                      </Columns.Column>
                      <Columns.Column size={6}>
                        <Field
                          label="Agence d'intérim"
                          name={`usagePeriods.${index}.interimAgency`}
                          component={SelectField}
                          options={buildListAsInputOptions(agenciesQuery.data || [])}
                          required
                        />
                      </Columns.Column>
                      <Columns.Column size={6}>
                        <Field
                          label="Employeur"
                          name={`usagePeriods.${index}.employer`}
                          component={SelectField}
                          options={buildListAsInputOptions(
                            getAssociatedEmployers(
                              String(values.usagePeriods?.[index]?.interimAgency),
                            ) || [],
                          )}
                          disabled={!values.usagePeriods?.[index]?.interimAgency}
                          required
                        />
                      </Columns.Column>
                    </Columns>
                  </Box>
                ))}
                <Button
                  onClick={() =>
                    push({
                      startDate: dateToDateInputValue(new Date()),
                      employer: '',
                      interimAgency: '',
                    })
                  }
                  size="small"
                >
                  Ajouter une période
                </Button>
              </div>
            )}
          </FieldArray>

          <Form.Field kind="group" mt={5}>
            <Form.Control>
              <RequestButton
                color="primary"
                type="submit"
                mutation={terminal ? updateMutation : createMutation}
              >
                {terminal ? 'Mettre à jour la borne de pointage' : 'Créer la borne de pointage'}
              </RequestButton>
            </Form.Control>
          </Form.Field>
        </FormikForm>
      )}
    </Formik>
  )
}

export default TerminalForm
