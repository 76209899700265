import { Paths, PathValue } from 'ts-essentials'
import { Tagged } from 'type-fest'

export type ResourcePath<Resource> = Paths<Resource, { depth: 3; anyArrayIndexAccessor: 'array' }> &
  string

type ResourcePathValue<Resource, Path extends ResourcePath<Resource>> = PathValue<Resource, Path>

type StringifiedMongooseFilterQuery = Tagged<string, 'StringifiedMongooseFilterQuery'>

export const stringifyMongooseFilterQuery = <Resource>(
  query: MongooseQuery<Resource>,
): StringifiedMongooseFilterQuery => {
  return JSON.stringify(query) as StringifiedMongooseFilterQuery
}

export type MongooseQuery<
  Resource,
  Path extends keyof ResourcePath<Resource> = ResourcePath<Resource>,
> = {
  [Key in Path | 'filter']?: Key extends 'filter'
    ? StringifiedMongooseFilterQuery
    : QueryItem<Resource, Key>
}

type QueryItem<Resource, Path extends keyof ResourcePath<Resource>> = {
  $gte?: ResourcePathValue<Resource, Path>
  $lte?: ResourcePathValue<Resource, Path>
  $elemMatch?: ResourcePathValue<Resource, Path> extends Array<infer ArrayType>
    ? MongooseQuery<ArrayType>
    : MongooseQuery<ResourcePathValue<Resource, Path>>
}

type Replace<
  T extends string,
  S extends string,
  D extends string,
  A extends string = '',
> = T extends `${infer L}${S}${infer R}` ? Replace<R, S, D, `${A}${L}${D}`> : `${A}${T}`

type StandardDottedPath<Resource> = Extract<
  Paths<Resource, { depth: 3; anyArrayIndexAccessor: `*` }>,
  string
>

type ReactQueryAccessorPath<Resource> = Replace<StandardDottedPath<Resource>, '.*.', `[${number}].`>
