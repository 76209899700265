import React from 'react'
import { User, UserRoleKind } from '../../../../api/users'
import { Organization } from '../../../../api/organizations'
import {
  getUserRole,
  getWorkerAbsentLabel,
  getWorkerAndOrganizationAssociation,
  getWorkerAvailabilityForOrganization,
} from '../../../../utils/users'
import usersLocales from '../../../../../../locales/users'
import { formatCompleteDateTime } from '../../../../utils/date'
import RoleStatus from '../../role-status'
import { isAfter } from 'date-fns'
import ResendUserInvitationButton from '../../buttons/resend-invitation'
import DiscontinueAssociationButton from '../../buttons/discontinue-association'
import EditUserButton from '../../buttons/edit-user'
import ShowQrCodeButton from '../../buttons/show-qr-code'
import { Column } from 'react-table'
import useStore from '../../../../store'
import { InfoIcon } from '../../../icons'
import { Dropdown, Element, Icon } from 'react-bulma-components'
import { ArrowDownIcon } from '@heroicons/react/outline'
import DeleteAssociationButton from '../../buttons/delete-association'

export type UserListRowKeys =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'internalRef'
  | 'internalComment'
  | 'internalInformation'
  | 'createdAt'
  | 'organizationRole'
  | 'associationStatus'
  | 'roleStatus'
  | 'availability'
  | 'weeklyHoursWorked'
  | 'monthlyHoursWorked'
  | 'qrCode'
  | 'actions'

type UserListRowData = Record<UserListRowKeys, React.ReactNode>

const useUsersTableData = (
  users: User[],
  roleKind: UserRoleKind,
  columns: UserListRowKeys[],
): { users: UserListRowData[]; columns: Column<UserListRowData>[] } => {
  const currentUser = useStore(state => state.session.currentUser as User)
  const currentOrganization = useStore(state => state.session.currentOrganization as Organization)
  return React.useMemo(
    () => ({
      users: users.map(user => mapUserToRowData(user, currentOrganization, roleKind, currentUser)),
      columns: buildColumns(columns),
    }),
    [users, currentOrganization, roleKind, currentUser],
  )
}

export default useUsersTableData

const buildColumns = (requestedColumns: UserListRowKeys[]): Column<UserListRowData>[] =>
  requestedColumns.reduce<Column<UserListRowData>[]>((tableColumns, column) => {
    const tableColumn = allColumns.find(c => c.accessor === column)
    tableColumn && tableColumns.push(tableColumn)
    return tableColumns
  }, [])

const mapUserToRowData = (
  user: User,
  organization: Organization,
  roleKind: UserRoleKind,
  currentUser: User,
): UserListRowData => {
  const association = getWorkerAndOrganizationAssociation(user, organization)
  const userRole = getUserRole(user, roleKind, organization?._id)
  const availability =
    roleKind === 'worker' && getWorkerAvailabilityForOrganization(user, organization)

  const associationOrRoleStatus = roleKind === 'worker' ? association?.status : userRole?.status

  const canAssociationBeDiscontinuedOrDeleted =
    associationOrRoleStatus && roleKind !== 'superAdmin' && user._id !== currentUser._id
  const canAssociationBeDiscontinued =
    canAssociationBeDiscontinuedOrDeleted &&
    (associationOrRoleStatus === 'accepted' || associationOrRoleStatus === 'pending')
  const canAssociationBeDeleted =
    canAssociationBeDiscontinuedOrDeleted &&
    roleKind === 'worker' &&
    (associationOrRoleStatus === 'rejected' || associationOrRoleStatus === 'discontinued')
  const canShowQrCode = association?.status === 'accepted' || association?.status === 'pending'
  const canUpdateUserInformation =
    roleKind !== 'interimAgencyMember' &&
    (association?.status === 'accepted' || association?.status === 'pending')

  const canRegenerateQrCode = user.appAccess === 'none' || user._id === currentUser._id

  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    internalRef: association?.internalRef,
    internalComment: association?.internalComment && (
      <Element
        data-tooltip={association.internalComment}
        data-test={`internal-comment-${user._id}`}
      >
        <Icon size="auto">
          <InfoIcon />
        </Icon>
      </Element>
    ),
    internalInformation: association?.internalInformation && (
      <Element
        data-tooltip={association.internalInformation}
        data-test={`internal-information-${user._id}`}
      >
        <Icon size="auto">
          <InfoIcon />
        </Icon>
      </Element>
    ),
    createdAt: formatCompleteDateTime(user.createdAt),
    organizationRole:
      userRole?.organizationRole && usersLocales.organizationRoles[userRole.organizationRole],
    associationStatus: association && <RoleStatus role={association} showDate />,
    roleStatus: userRole ? <RoleStatus role={userRole} showDate /> : '',
    availability:
      availability &&
      availability.absentFrom &&
      (!availability.absentTo || isAfter(new Date(availability.absentTo), new Date()))
        ? getWorkerAbsentLabel(availability, user, true)
        : '',
    weeklyHoursWorked: association?.schedule.weeklyHoursWorked,
    monthlyHoursWorked: association?.schedule.monthlyHoursWorked,
    qrCode: canShowQrCode ? (
      <ShowQrCodeButton user={user} canRegenerate={canRegenerateQrCode} />
    ) : (
      ''
    ),
    actions: (
      <Dropdown
        right
        icon={<ArrowDownIcon />}
        label="Actions"
        data-test={`user-actions-${user._id}`}
        className="is-small"
      >
        {canUpdateUserInformation ? (
          <Dropdown.Item
            renderAs="div"
            data-test={`edit-user-${user._id}`}
            value={`${user._id}_edit`}
          >
            <EditUserButton
              user={user}
              userRoleKind={roleKind}
              label="Modifier les informations"
              color={'ghost'}
            />
          </Dropdown.Item>
        ) : (
          <></>
        )}

        {associationOrRoleStatus && associationOrRoleStatus !== 'accepted' ? (
          <Dropdown.Item
            renderAs="div"
            data-test={`resend-invitation-${user._id}`}
            value={`${user._id}_resend`}
          >
            <ResendUserInvitationButton
              userEmail={user.email}
              organization={organization}
              roleKind={roleKind}
              color={'ghost'}
            />
          </Dropdown.Item>
        ) : (
          <></>
        )}

        {canAssociationBeDiscontinued ? (
          <Dropdown.Item
            renderAs="div"
            data-test={`discontinue-association-${user._id}`}
            value={`${user._id}_discontinue`}
          >
            <DiscontinueAssociationButton
              user={user}
              organization={organization}
              roleKind={roleKind}
              color={'ghost'}
            />
          </Dropdown.Item>
        ) : (
          <></>
        )}

        {canAssociationBeDeleted ? (
          <Dropdown.Item
            renderAs="div"
            data-test={`discontinue-association-${user._id}`}
            value={`${user._id}_discontinue`}
          >
            <DeleteAssociationButton
              user={user}
              roleKind={roleKind}
              key={user._id}
              color={'ghost'}
            />
          </Dropdown.Item>
        ) : (
          <></>
        )}
      </Dropdown>
    ),
  }
}

const allColumns: Column<UserListRowData>[] = [
  {
    Header: 'Prénom',
    accessor: 'firstName',
  },
  {
    Header: 'Nom',
    accessor: 'lastName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Matricule',
    accessor: 'internalRef',
  },
  {
    Header: 'Com.',
    accessor: 'internalComment',
  },
  {
    Header: 'Info.',
    accessor: 'internalInformation',
  },
  {
    Header: 'Créé le',
    accessor: 'createdAt',
  },
  {
    Header: 'Rôle',
    accessor: 'organizationRole',
  },
  {
    Header: 'Statut',
    accessor: 'associationStatus',
  },
  {
    Header: 'Statut',
    accessor: 'roleStatus',
  },
  {
    Header: 'Indisponi.',
    accessor: 'availability',
  },
  {
    Header: 'H / Sem.',
    accessor: 'weeklyHoursWorked',
  },
  {
    Header: 'H / Mois',
    accessor: 'monthlyHoursWorked',
  },
  {
    Header: 'QR',
    accessor: 'qrCode',
  },
  {
    Header: ' ',
    accessor: 'actions',
  },
]
