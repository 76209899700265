import React from 'react'
import { Box, Container, Element, Level, Section } from 'react-bulma-components'
import PageTitle from '../pages/page-title'
import Layout from './layout'

/**
 * Props d'interface pour le composant ListPageLayout
 */
interface ListPageLayoutProps {
  /** Titre principal de la page */
  title: string
  /** Sous-titre de la page (optionnel) */
  subtitle?: string
  /** Composant d'action à afficher dans le coin supérieur droit (optionnel) */
  action?: React.ReactNode
  /** Composant de filtres à afficher au-dessus du tableau (optionnel) */
  filters?: React.ReactNode
  /** Contenu principal (généralement un tableau) */
  children: React.ReactNode
}

/**
 * Composant de mise en page réutilisable pour les pages de type liste
 */
const ListPageLayout: React.FC<ListPageLayoutProps> = ({
  title,
  subtitle,
  action,
  filters,
  children,
}) => {
  return (
    <Layout>
      <Section>
        <Container>
          <Level alignItems="center" mb={4}>
            <Level.Side>
              <PageTitle title={title} subTitle={subtitle} />
            </Level.Side>
            {action && <Level.Side>{action}</Level.Side>}
          </Level>

          <Box>
            {filters && <Element mb={6}>{filters}</Element>}
            <Element mt={filters ? 6 : 0}>{children}</Element>
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export default ListPageLayout
