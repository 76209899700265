import React from 'react'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import MultiSelectField from '../../form/fields/multi-select'
import { buildListAsInputOptions } from '../../../utils/forms'
import { useServicesQuery } from '../../../queries/services'

interface ServiceFilterFieldProps {
  disabledQueries?: boolean
  queriesStaleTime?: number
  employerIds?: string[]
}

const ServiceFilterField: React.FC<ServiceFilterFieldProps> = ({
  disabledQueries,
  queriesStaleTime,
  employerIds,
}) => {
  const servicesQuery = useServicesQuery(
    { employer: employerIds },
    {
      enabled: !disabledQueries,
      staleTime: queriesStaleTime,
    },
  )

  return (
    <FieldColumn>
      <Field
        label="Service"
        name="service"
        component={MultiSelectField}
        options={buildListAsInputOptions(servicesQuery.data || [])}
      />
    </FieldColumn>
  )
}

export default ServiceFilterField
