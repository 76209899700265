import React from 'react'
import Layout from '../../components/layout/layout'
import { Container, Section } from 'react-bulma-components'
//@ts-ignore
import changelog from '../../../changelog.md'

export const WhatsNewPage: React.FC = () => {
  return (
    <Layout>
      <Section size="medium">
        <Container max={true} breakpoint={'desktop'}>
          <div className="content" dangerouslySetInnerHTML={{ __html: changelog }} />
        </Container>
      </Section>
    </Layout>
  )
}
