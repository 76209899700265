import React from 'react'
import { PreBilling } from '../../api/pre-billings'
import { Column } from 'react-table'
import { ResourceLinkCell } from '../../components/table/cells'
import { Element } from 'react-bulma-components'
import Table from '../../components/table'
import { usePreBillingsQuery } from '../../queries/pre-billings'
import { localDate } from '../../utils/date'
import useStore from '../../store'
import { SessionSlice } from '../../store/session'
import useFilters from '../../hooks/filters'
import PreBillingsFilters from '../../components/pre-bilings/filters'
import { FiltersResourcesEnum } from '../../store/filters'
import ListPageLayout from '../../components/layout/list-page-layout'

const PreBillingsList: React.FC = () => {
  const currentUserRole = useStore(({ session }) => session.currentUserRole)
  const { filters } = useFilters(FiltersResourcesEnum.preBillings)
  const tableColumns = useListTableColumns(currentUserRole)

  const preBillingsQuery = usePreBillingsQuery({
    interimAgency: filters.interimAgency,
    'createdAt>': filters.start || null,
    'createdAt<': filters.end || null,
    sort: '-createdAt',
    invoiceRef: filters.invoiceRef,
  })

  const preBillings = preBillingsQuery.data || []
  const totalCost = preBillings.reduce<number>(
    (total, preBilling) => (total += preBilling.missions.totals.costs.total),
    0,
  )

  const action = (
    <Element textSize={5}>
      <Element renderAs="span">Total : </Element>
      <Element renderAs="strong">&nbsp;{totalCost.toFixed(2)} €</Element>
    </Element>
  )

  return (
    <ListPageLayout
      title="Liste des Pré-Facturations"
      subtitle="Un Employeur génère une Pré-facturation via son Récapitulatif"
      action={action}
      filters={<PreBillingsFilters />}
    >
      <Table
        columns={tableColumns}
        data={preBillings}
        noDataMessage="Aucune pré-facturation à afficher"
      />
    </ListPageLayout>
  )
}

export default PreBillingsList

const useListTableColumns = (
  currentUserRole: SessionSlice['currentUserRole'],
): Column<PreBilling>[] => {
  return React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'identifier',
        Cell: data => (
          <ResourceLinkCell
            resource={data.row.original}
            path="/pre-billings/"
            label={data.row.original.identifier}
          />
        ),
      },
      {
        Header: currentUserRole === 'interimAgencyMember' ? 'Employeur' : 'Agence',
        accessor: currentUserRole === 'interimAgencyMember' ? 'employer' : 'interimAgency',
        Cell: (data: any) =>
          currentUserRole === 'interimAgencyMember'
            ? data.row.original.employer.name
            : data.row.original.interimAgency.name,
      },
      {
        Header: 'N° Facture',
        //@ts-ignore
        accessor: 'invoiceRef',
      },
      {
        Header: 'Semaine',
        //@ts-ignore
        accessor: 'missions.week',
      },
      {
        Header: 'Créé le',
        accessor: 'createdAt',
        Cell: data => localDate(data.row.original.createdAt),
      },
      {
        Header: 'Nbr. missions',
        accessor: 'missions',
        Cell: data => data.row.original.missions.count,
      },
      {
        Header: 'Coût',
        //@ts-ignore
        accessor: 'missions.totals',
        //@ts-ignore
        Cell: data => `${data.row.original.missions.totals.costs.total} €`,
      },
    ],
    [],
  )
}
