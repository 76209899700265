import { AppSubscriptionPeriodStatusEnum } from "../backend/src/services/resources/app-subscription-periods/app-subscription-period.types"

export default {
  status: {
    draft: 'En attente de facturation',
    validated: 'Période terminée et facturée',
    inProgress: 'Période en cours',
  }
} as {
  status: {
    [key in AppSubscriptionPeriodStatusEnum]: string
  }
}