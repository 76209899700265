import React from 'react'
import Table from '../table'
import { Column } from 'react-table'
import { Terminal } from '../../api/terminals'
import { Button } from 'react-bulma-components'
import { formatDate } from '../../utils/date'
import { getCurrentPeriods } from '../../utils/terminals'
import { formatCost } from '../../utils/finance'
import { isAuthorizedFor } from '../protected/protected'
import { Employer, InterimAgency } from '../../api/organizations'

/**
 * Component qui affiche un tableau des bornes de pointage
 */
const TerminalsList: React.FC<{
  data: Terminal[]
  onTerminalClick?: (terminal: Terminal) => void
}> = ({ data, onTerminalClick }) => {
  const tableColumns = useListTableColumns(data, onTerminalClick)

  return (
    <Table columns={tableColumns} data={data} noDataMessage="Aucune borne de pointage à afficher" />
  )
}

const useListTableColumns = (
  terminals: Terminal[],
  onTerminalClick?: (terminal: Terminal) => void,
): Column[] => {
  return React.useMemo(
    () =>
      [
        {
          Header: 'Modèle',
          accessor: 'device.name',
          Cell: (data: any) => {
            const terminal: Terminal = data.cell.row.original
            if (!onTerminalClick) return terminal.device.name
            return (
              <Button
                onClick={() => onTerminalClick(terminal)}
                color="ghost"
                className="has-text-left p-0"
              >
                {terminal.device.name}
              </Button>
            )
          },
        },
        {
          Header: 'N° de série',
          accessor: 'device.serialNumber',
        },
        {
          Header: 'Agence / Client',
          accessor: 'interimAgency',
          Cell: (data: any) => {
            const terminal: Terminal = data.cell.row.original
            const currentPeriods = getCurrentPeriods(terminal)
            return currentPeriods.length > 0
              ? currentPeriods
                  .map(
                    period =>
                      (period.interimAgency as InterimAgency | undefined)?.name || 'Non assigné',
                  )
                  .map((name, index) => (
                    <React.Fragment key={index}>
                      {name}
                      {index < currentPeriods.length - 1 && <br />}
                    </React.Fragment>
                  ))
              : 'Non assigné'
          },
        },
        {
          Header: 'Employeur',
          accessor: 'employer',
          Cell: (data: any) => {
            const terminal: Terminal = data.cell.row.original
            const currentPeriods = getCurrentPeriods(terminal)
            return currentPeriods.length > 0
              ? currentPeriods
                  .map(period => (period.employer as Employer | undefined)?.name || 'Non assigné')
                  .map((name, index) => (
                    <React.Fragment key={index}>
                      {name}
                      {index < currentPeriods.length - 1 && <br />}
                    </React.Fragment>
                  ))
              : 'Non assigné'
          },
        },
        isAuthorizedFor(['interimAgencyMember', 'superAdmin']) && {
          Header: 'Cout actuel',
          accessor: 'rentalCost',
          Cell: (data: any) => {
            const terminal: Terminal = data.cell.row.original
            const currentPeriods = getCurrentPeriods(terminal)
            return currentPeriods.length > 0
              ? currentPeriods
                  .map(period => `${formatCost(period.rentalCost)} / semaine`)
                  .map((cost, index) => (
                    <React.Fragment key={index}>
                      {cost}
                      {index < currentPeriods.length - 1 && <br />}
                    </React.Fragment>
                  ))
              : 'Non assigné'
          },
        },
        {
          Header: 'Début location',
          accessor: 'startDate',
          Cell: (data: any) => {
            const terminal: Terminal = data.cell.row.original
            const currentPeriods = getCurrentPeriods(terminal)
            return currentPeriods.length > 0
              ? currentPeriods
                  .map(period => formatDate(period.startDate))
                  .map((date, index) => (
                    <React.Fragment key={index}>
                      {date}
                      {index < currentPeriods.length - 1 && <br />}
                    </React.Fragment>
                  ))
              : 'Non assigné'
          },
        },
        {
          Header: 'Fin location',
          accessor: 'endDate',
          Cell: (data: any) => {
            const terminal: Terminal = data.cell.row.original
            const currentPeriods = getCurrentPeriods(terminal)
            return currentPeriods.length > 0
              ? currentPeriods
                  .map(period => (period.endDate ? formatDate(period.endDate) : 'N/A'))
                  .map((date, index) => (
                    <React.Fragment key={index}>
                      {date}
                      {index < currentPeriods.length - 1 && <br />}
                    </React.Fragment>
                  ))
              : 'Non assigné'
          },
        },
        /*{
        Header: 'Actif',
        accessor: 'status',
        Cell: (data: any) => {
          const terminal: Terminal = data.cell.row.original
          return (
            <Icon color={terminal.status === TerminalStatusEnum.active ? 'success' : 'danger'}>
              {terminal.status === TerminalStatusEnum.active ? <CheckIcon /> : <RemoveIcon />}
            </Icon>
          )
        },
      },*/
      ].filter(Boolean) as Column[],
    [terminals, onTerminalClick],
  )
}

export default TerminalsList
