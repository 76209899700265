import { UseQueryResult } from '@tanstack/react-query'
import { QueryOptions, useResources } from '.'
import { SmicSchema } from '../../../backend/src/services/providers/insee/smic.model'
import { get } from '../api/smic'

//Queries
export const useSmicQuery = (date: Date, options?: QueryOptions): UseQueryResult<SmicSchema, any> =>
  useResources<SmicSchema, any>('pre-billings', get, date, {
    staleTime: 1000 * 60 * 60,
    ...(options || {}),
  })
