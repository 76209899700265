import { addDays, differenceInDays, nextSunday } from 'date-fns'
import {
  WorkersRequestCreateFormValues,
  WorkersRequestCreateUpdateFormValues,
  WorkersRequestFormProps,
  WorkersRequestUpdateFormValues,
} from '../workers-request-form'
import { User } from '../../../api/users'
import { formatISODate } from '../../../utils/date'

export const newPostTemplate: WorkersRequestCreateFormValues['posts'][0] = Object.freeze({
  count: 1,
  jobTitle: '',
  recourseCase: {
    reason: '',
    justification: '',
  },
  desiredWorkers: [],
  description: '',
})

export const getWorkersRequestFormInitialValues = (
  props: WorkersRequestFormProps,
): WorkersRequestCreateUpdateFormValues => {
  const { workersRequest, fromTemplate } = props

  // start and end dates stored as ISO dates YYYY-mm-dd
  // we always display at least 3 days
  const now = new Date()
  const today = now
  const todayISO = formatISODate(now)
  const sunday = nextSunday(now)
  const end = differenceInDays(sunday, today) <= 3 ? addDays(sunday, 7) : sunday
  const endISO = formatISODate(end)

  if (workersRequest) {
    // We will update an existing workers request
    const firstWorkPeriodStartDate = workersRequest.missionsStart
    const startDateISO = formatISODate(new Date(firstWorkPeriodStartDate))
    const lastWorkPeriodStartDate = workersRequest.missionsEnd
    const endDateISO = formatISODate(new Date(lastWorkPeriodStartDate))

    const initialValues: WorkersRequestUpdateFormValues = {
      meta: {
        startDate: startDateISO,
        endDate: endDateISO,
        resource: workersRequest,
      },
      interimAgency: workersRequest.interimAgency._id,
      missionData: {
        workPeriods: workersRequest.missionData.workPeriods.map(wP => ({
          ...wP,
          start: { date: new Date(wP.start.date) },
          end: { date: new Date(wP.end.date) },
        })),
        service: workersRequest?.missionData.service?._id ?? '',
        address: workersRequest?.missionData.address,
        complementaryInformations: workersRequest.missionData.complementaryInformations ?? '',
      },
      posts: workersRequest.posts.map(post => {
        return {
          ...post,
          desiredWorkers: post.desiredWorkers.map(dW => dW._id.toString()),
          jobTitle: post.jobTitle._id,
          count: 1,
        }
      }),
    }
    return initialValues
  }
  if (fromTemplate?.mission) {
    // We create a new workers request and use an existing mission as template
    const { mission } = fromTemplate

    const startDateISO = todayISO
    const endDateISO = endISO

    const initialValues: WorkersRequestCreateFormValues = {
      meta: {
        startDate: startDateISO,
        endDate: endDateISO,
        resource: undefined,
      },
      interimAgency: mission.interimAgency?._id ?? '',
      missionData: {
        /* workPeriods: mission.workPeriods.map(wP => ({
          ...wP,
          start: { date: new Date(wP.start.date) },
          end: { date: new Date(wP.end.date) },
        })), */
        workPeriods: [],
        service: mission.service?._id ?? '',
        address: mission.address,
        complementaryInformations: mission.complementaryInformations ?? '',
      },
      posts: [
        {
          ...newPostTemplate,
          jobTitle: mission.jobTitle._id.toString(),
          recourseCase: mission.workersRequestOfMission.postOfMission.recourseCase,
          description: mission.workersRequestOfMission.postOfMission.description,
          desiredWorkers: [mission.user._id],
        },
      ],
    }
    return initialValues
  }
  if (fromTemplate?.workersRequest) {
    // We create a new workers request and use an existing workers request as template
    const { workersRequest } = fromTemplate

    const startDateISO = todayISO
    const endDateISO = endISO

    const posts: WorkersRequestCreateFormValues['posts'] = workersRequest.posts
      // Mandatory to only keep needed parameters
      // The TS interface guarantees completeness
      .map(p => {
        const post = {
          count: 1,
          desiredWorkers: p.desiredWorkers.map(dW => dW._id.toString()),
          jobTitle: p.jobTitle._id,
          recourseCase: p.recourseCase,
          description: p.description ?? '',
        }
        const lastMissionOfPost = p.missions?.[p.missions.length - 1]
        if (lastMissionOfPost && !lastMissionOfPost.isCancelled && !lastMissionOfPost.isAbandoned) {
          post.desiredWorkers.push(lastMissionOfPost.user._id.toString())
        }
        return post
      })
      // Fold together posts that are the same
      .reduce((acc, current) => {
        const previous = structuredClone(acc.pop())
        if (previous) {
          const {
            count: _drop1,
            desiredWorkers: previousDesiredWorkers,
            ...previousWithoutCount
          } = previous
          const {
            count: _drop2,
            desiredWorkers: currentDesiredWorkers,
            ...currentWithoutCount
          } = current
          if (JSON.stringify(previousWithoutCount) === JSON.stringify(currentWithoutCount)) {
            previous.count = previous.count + 1
            previous.desiredWorkers = [...previousDesiredWorkers, ...currentDesiredWorkers]
              .filter((id): id is string => !!id)
              .filter((id, index, all) => {
                return all.indexOf(id) === index
              })
            return [...acc, previous]
          } else {
            previous.desiredWorkers = [...previousDesiredWorkers]
              .filter((id): id is string => !!id)
              .filter((id, index, all) => {
                return all.indexOf(id) === index
              })
            current.desiredWorkers = [...currentDesiredWorkers]
              .filter((id): id is string => !!id)
              .filter((id, index, all) => {
                return all.indexOf(id) === index
              })
            return [...acc, previous, current]
          }
        } else {
          current.desiredWorkers = [...current.desiredWorkers]
            .filter((id): id is string => !!id)
            .filter((id, index, all) => {
              return all.indexOf(id) === index
            })
          return [current]
        }
      }, [] as Array<WorkersRequestCreateFormValues['posts'][number]>)

    const initialValues: WorkersRequestCreateFormValues = {
      meta: {
        startDate: startDateISO,
        endDate: endDateISO,
        resource: undefined,
      },
      interimAgency: workersRequest.interimAgency._id,
      missionData: {
        /* workPeriods: workersRequest.missionData.workPeriods.map(wP => ({
          ...wP,
          start: { date: new Date(wP.start.date) },
          end: { date: new Date(wP.end.date) },
        })), */
        workPeriods: [],
        service: workersRequest.missionData.service?._id ?? '',
        address: workersRequest.missionData.address,
        complementaryInformations: workersRequest.missionData.complementaryInformations,
      },
      posts,
    }
    return initialValues
  }

  // We send blank default initial values
  const initialValues: WorkersRequestCreateFormValues = {
    meta: {
      startDate: todayISO,
      endDate: endISO,
      resource: undefined,
    },
    interimAgency: '',
    missionData: {
      workPeriods: [],
      service: '',
      address: '',
      complementaryInformations: '',
    },
    posts: [newPostTemplate],
  }
  return initialValues
}
