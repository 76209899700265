import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContext } from './employer-settings-wrapper'
import { Box, Element, Heading } from 'react-bulma-components'
import AssociationsTable from '../../components/interim-agencies/associations-table'
import OrganizationAssociationsList from '../../components/organizations/associations-list'
import { OrganizationTypeEnum } from '../../api/organizations'
import { useUpdateAssocationMutation } from '../../queries/organizations'
import SidebarContentTitle from '../../components/layout/sidebar/sidebar-content-title'
import EmployerAgencyExplanation from '../../components/organizations/employer-agency-explanation'

/**
 * Component for managing partner agencies
 */
const PartnersAgenciesSettings: React.FC = () => {
  const { employer } = useOutletContext<OutletContext>()

  const updateAssociationMutation = useUpdateAssocationMutation()

  const handleAcceptInvitation = (associationId: string) => {
    updateAssociationMutation.mutate({
      employerId: employer._id,
      associationId: associationId,
      status: 'accepted',
    })
  }

  return (
    <>
      <SidebarContentTitle
        title="Agences d'Interim Partenaires"
        help={<EmployerAgencyExplanation textColor={'white'} />}
      />
      <Box mb={1}>
        <OrganizationAssociationsList
          associations={employer.associations}
          viewType={OrganizationTypeEnum.employer}
          onAcceptInvitation={handleAcceptInvitation}
        />
      </Box>
      <Element renderAs="p" textColor="grey">
        Pour collaborer avec un nouveau partenaire, veuillez contacter votre administrateur TeamTim.
      </Element>
    </>
  )
}

export default PartnersAgenciesSettings
