import { Formik } from 'formik'
import React from 'react'
import { Columns } from 'react-bulma-components'
import useFilters from '../../hooks/filters'
import { FiltersResourcesEnum, WorkersRequestsFilters } from '../../store/filters'
import AutoSubmit from '../form/utils/auto-submit'
import Protected from '../protected/protected'
import JobTitleFilterField from '../filters/fields/job-title'
import ServiceFilterField from '../filters/fields/service'
import IsCancelledFilterField from '../filters/fields/is-cancelled'
import IsExpiredFilterField from '../filters/fields/is-expired'
import IsCompleteFilterField from '../filters/fields/is-complete'
import OrganizationFilterField from '../filters/fields/organization'
import { OrganizationTypeEnum } from '../../api/organizations'
import WeekFilterField from '../filters/fields/week'
import {
  dateInputToDateValue,
  dateInputValuesToWeekDates,
  dateToDateInputValue,
} from '../../utils/date'
import { startOfDay, subDays } from 'date-fns'
import { isArray, isDate } from 'lodash'

interface MissionFiltersProps {
  disabledQueries?: boolean
}

const WorkersRequestsFilters: React.FunctionComponent<MissionFiltersProps> = ({
  disabledQueries,
}) => {
  const { setFilters, filters } = useFilters(FiltersResourcesEnum.workersRequests)
  const queriesStaleTime = 1000 * 60 * 2 // 2 min

  return (
    <Formik<Omit<WorkersRequestsFilters, 'isExpired'> & { week?: string; isExpired?: string }>
      initialValues={{
        ...filters,
        isExpired: dateToDateInputValue(
          isArray(filters.isExpired) ? subDays(startOfDay(new Date()), 3) : filters.isExpired,
        ),
      }}
      onSubmit={values => {
        if (values.week) {
          const dates = dateInputValuesToWeekDates(values.week)
          if (dates) values = { ...values, ...dates }
        }
        if (values.isExpired) {
          const date = dateInputToDateValue(values.isExpired)
          if (date) values = { ...values, isExpired: date }
        }
        setFilters(FiltersResourcesEnum.workersRequests, values)
      }}
    >
      {props => {
        return (
          <>
            <Columns mb="0">
              <WeekFilterField />
              <Protected roles={['employerMember']}>
                <OrganizationFilterField
                  type={OrganizationTypeEnum.interimAgency}
                  disabledQueries={disabledQueries}
                  queriesStaleTime={queriesStaleTime}
                />
              </Protected>
              <Protected roles={['employerMember']}>
                <ServiceFilterField
                  employerIds={filters.employer}
                  disabledQueries={disabledQueries}
                  queriesStaleTime={queriesStaleTime}
                />
              </Protected>
              <Protected roles={['interimAgencyMember']}>
                <OrganizationFilterField
                  type={OrganizationTypeEnum.employer}
                  disabledQueries={disabledQueries}
                  queriesStaleTime={queriesStaleTime}
                />
              </Protected>
              <Protected roles={['employerMember']}>
                <JobTitleFilterField
                  employerIds={filters.employer}
                  disabledQueries={disabledQueries}
                  queriesStaleTime={queriesStaleTime}
                />
              </Protected>
            </Columns>
            <Columns>
              <IsExpiredFilterField />
              <IsCompleteFilterField />
              <IsCancelledFilterField />
            </Columns>
            <AutoSubmit values={props.values} submitForm={props.submitForm} />
          </>
        )
      }}
    </Formik>
  )
}

export default WorkersRequestsFilters
