import React from 'react'
import { Column } from 'react-table'
import Table from '../table'
import { eachDayOfInterval, format, getDay, isSameDay, setYear } from 'date-fns'
import {
  endOfWeek,
  formatDayName,
  formatTime,
  setWeek,
  startOfWeek,
  formatDuration,
  getWeekdayMondayBased,
  formatDurationAsDecimal,
} from '../../utils/date'
import { Element } from 'react-bulma-components'
import { sortBy } from 'lodash'
import DescriptionList from '../description-list/description-list'
import {
  WorkPeriodAbandonedMessage,
  WorkPeriodCancelledMessage,
  WorkPeriodEventsMessage,
  WorkPeriodNotValidatedMessage,
  WorkPeriodValidatedMessage,
} from '../work-periods/work-period-messages'
import { MissionSummary, UserSummaries } from '../../api/missions'
import { WorkPeriodEvent } from '../../api/work-period-events'
import { buildWorkPeriodEventLabel } from '../../utils/work-period-events'
import {
  MissionWeekDaySummary,
  MissionWeekSummary,
} from '../../../../backend/src/services/resources/missions/missions.summary.service.types'
import { WorkPeriodWithSummary } from '../../api/work-periods'

interface PayrollWeekTableProps {
  weekSummary?: UserSummaries['weeks'][0]
  weekNumber: number
  year: number
  weeklyHoursWorked?: number
  events: WorkPeriodEvent[]
}

interface ColumnData {
  messages?: React.ReactNode
  dayName: React.ReactNode
  date?: string | null
  theoriticalStart?: string | null
  theoriticalEnd?: string | null
  countedStart?: string | null
  countedEnd?: string | null
  theoreticalDuration?: React.ReactNode | null
  countedDuration?: React.ReactNode | null
  countedBreak?: string | null
  events?: React.ReactNode
  overtime?: React.ReactNode | null
  contractualOvertime?: React.ReactNode | null
  [key: string]: React.ReactNode | null
}

const PayrollWeekTable: React.FC<PayrollWeekTableProps> = ({
  weekSummary,
  weekNumber,
  year,
  weeklyHoursWorked,
  events,
}) => {
  const weekStartDay = startOfWeek(setWeek(setYear(new Date(), year), weekNumber))
  const weekDays = eachDayOfInterval({ start: weekStartDay, end: endOfWeek(weekStartDay) })
  const employerRules = weekSummary?.employerRules.filter(r => r.display.payroll)
  const tableColumns = useColumns(events, employerRules || [])

  const data = weekDays.reduce<ColumnData[]>((tableData, day) => {
    const allWorkPeriods = sortBy(
      weekSummary?.missions.flatMap(m => m.workPeriods) || [],
      'start.date',
    )

    const workPeriodOfDay = allWorkPeriods.filter(w => isSameDay(new Date(w.start.date), day))

    if (!workPeriodOfDay[0]) tableData.push(initWorkPeriodRowData(day))
    else if (workPeriodOfDay[0]) {
      workPeriodOfDay.forEach(workPeriod => {
        tableData.push(
          buildWorkPeriodRowData(
            workPeriod,
            day,
            events,
            //@ts-ignore
            weekSummary?.totals.days[getWeekdayMondayBased(getDay(day))],
            (employerRules || []).filter(e => e.period === 'day'),
          ),
        )
      })
    }

    return tableData
  }, [])

  const employerRulesVales =
    weekSummary?.totals && employerRules
      ? getRuleValuesFromSummary(employerRules, weekSummary.totals)
      : {}

  const employerRulesValuesWithDecimalValues =
    weekSummary?.totals && employerRules
      ? getRuleValuesFromSummary(employerRules, weekSummary.totals, formatDurationAsDecimal)
      : {}

  data.push({
    dayName: <strong>TOTAL</strong>,
    date: `Contrat ${weeklyHoursWorked}h`,
    theoreticalDuration: weekSummary?.totals.durations.worked.theoretical
      ? formatDurationAsDecimal(weekSummary?.totals.durations.worked.theoretical)
      : undefined,
    countedDuration: (
      <WeekWorkedDurationCell
        weekSummary={weekSummary}
        weeklyHoursWorked={weeklyHoursWorked}
        durationFormatter={formatDuration}
      />
    ),
    overtime: weekSummary?.totals.durations.overtime.total
      ? formatDuration(weekSummary?.totals.durations.overtime.total)
      : '0',
    countedBreak: weekSummary?.totals.durations.breaks
      ? formatDuration(weekSummary?.totals.durations.breaks)
      : undefined,
    contractualOvertime: weekSummary?.totals.durations.overtime.contractual ? (
      <DurationDiff duration={weekSummary?.totals.durations.overtime.contractual} />
    ) : undefined,
    ...employerRulesVales,
  })
  data.push({
    dayName: '',
    date: '',
    theoreticalDuration: weekSummary?.totals.durations.worked.theoretical
      ? formatDurationAsDecimal(weekSummary?.totals.durations.worked.theoretical)
      : undefined,
    countedDuration: (
      <WeekWorkedDurationCell
        weekSummary={weekSummary}
        weeklyHoursWorked={weeklyHoursWorked}
        durationFormatter={formatDurationAsDecimal}
      />
    ),
    overtime: weekSummary?.totals.durations.overtime.total
      ? formatDurationAsDecimal(weekSummary?.totals.durations.overtime.total)
      : '0',
    countedBreak: weekSummary?.totals.durations.breaks
      ? formatDurationAsDecimal(weekSummary?.totals.durations.breaks)
      : undefined,
    contractualOvertime: weekSummary?.totals.durations.overtime.contractual ? (
      <DurationDiff
        duration={weekSummary?.totals.durations.overtime.contractual}
        durationFormatter={formatDurationAsDecimal}
      />
    ) : undefined,
    ...employerRulesValuesWithDecimalValues,
  })

  return (
    <>
      <Table columns={tableColumns} data={data} noDataMessage="..." />
      {weekSummary && (
        <Element style={{ marginTop: -32 }}>
          <DescriptionList
            labelColumnWidth={560}
            items={[
              {
                label: 'Heures Contractuelles de Base à prester sur la semaine  / Ecart',
                value: (
                  <>
                    {formatDuration(weekSummary.totals.durations.contract.legal || 0)} /{' '}
                    <DurationDiff
                      duration={weekSummary.totals.durations.worked.contract.legalDiff}
                      showZero
                    />
                  </>
                ),
              },
              {
                label: 'Heures Contractuelles Supplémentaires à prester sur la période  / Ecart',
                value: (
                  <>
                    {formatDuration(weekSummary.totals.durations.contract.extraLegal || 0)} /{' '}
                    <DurationDiff
                      duration={weekSummary.totals.durations.worked.contract.extraLegalDiff}
                      showZero
                    />
                  </>
                ),
              },
              {
                label: 'Heures Supplémentaires Non-Contractuelles ',
                value: (
                  <DurationDiff
                    duration={weekSummary.totals.durations.overtime.nonContractual || undefined}
                    showZero
                  />
                ),
              },
            ]}
          />
        </Element>
      )}
    </>
  )
}

export default PayrollWeekTable

const buildWorkPeriodRowData = (
  workPeriod: WorkPeriodWithSummary,
  day: Date,
  events: WorkPeriodEvent[],
  missionDaySummary: MissionSummary['weeks'][0]['days'][0],
  employerRules: UserSummaries['weeks'][0]['employerRules'],
): ColumnData => {
  const countedStart =
    workPeriod?.summary.start.counted &&
    !workPeriod.summary.isBenefitWorkPeriod &&
    workPeriod.summary.start[workPeriod?.summary.start.counted]
  const countedEnd =
    workPeriod?.summary.end.counted &&
    !workPeriod.summary.isBenefitWorkPeriod &&
    workPeriod.summary.end[workPeriod?.summary.end.counted]

  const benefitEvents = events
    .filter(e => e.type === 'benefit')
    .reduce<{ [key: string]: React.ReactNode }>((events, event) => {
      events[`event_${event.code}`] = workPeriod.events.some(e => e.item._id === event._id) ? (
        <Element data-tooltip={buildWorkPeriodEventLabel(event)}>1</Element>
      ) : (
        ''
      )
      return events
    }, {})
  const informativeEvents = workPeriod.events.filter(e => e.item.type === 'informative')

  const workPeriodRulesValues = getRuleValuesFromSummary(employerRules || [], missionDaySummary)

  return {
    ...initWorkPeriodRowData(day),
    messages: (
      <>
        <WorkPeriodValidatedMessage workPeriod={workPeriod} small type="icon" />
        <WorkPeriodNotValidatedMessage workPeriod={workPeriod} small type="icon" />
        <WorkPeriodCancelledMessage workPeriod={workPeriod} small type="icon" />
        <WorkPeriodAbandonedMessage workPeriod={workPeriod} small type="icon" />
      </>
    ),
    countedStart: countedStart ? formatTime(countedStart) : '',
    countedEnd: countedEnd ? formatTime(countedEnd) : '',
    countedDuration:
      (workPeriod.summary.durations?.worked.total &&
        formatDuration(workPeriod.summary.durations.worked.total)) ||
      undefined,
    countedBreak: workPeriod.summary.durations.breaks
      ? formatDuration(workPeriod.summary.durations.breaks)
      : undefined,
    theoreticalDuration: formatDuration(workPeriod.summary.durations.worked.theoretical),
    theoriticalStart: formatTime(workPeriod.start.date),
    theoriticalEnd: formatTime(workPeriod.end.date),
    overtime:
      workPeriod.summary.durations.diff && workPeriod.summary.durations.diff >= 0 ? (
        <DurationDiff duration={workPeriod.summary.durations.diff} />
      ) : undefined,
    events: informativeEvents[0] && (
      <WorkPeriodEventsMessage small type="icon" workPeriod={{ events: informativeEvents }} />
    ),
    ...benefitEvents,
    ...workPeriodRulesValues,
  }
}

const initWorkPeriodRowData = (day: Date): ColumnData => {
  return {
    dayName: formatDayName(day),
    date: format(day, 'dd MMMM'),
  }
}

const useColumns = (
  events: WorkPeriodEvent[],
  employerRules: UserSummaries['weeks'][0]['employerRules'],
): Column<ColumnData>[] => {
  const benefitEvents = events.filter(e => e.type === 'benefit')

  return React.useMemo(
    () => [
      {
        Header: 'Jour',
        accessor: 'dayName',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Entrée T.',
        accessor: 'theoriticalStart',
      },
      {
        Header: 'Sortie T.',
        accessor: 'theoriticalEnd',
      },
      {
        Header: 'Total T.',
        accessor: 'theoreticalDuration',
      },
      {
        Header: 'Entrée P.',
        accessor: 'countedStart',
      },
      {
        Header: 'Sortie P.',
        accessor: 'countedEnd',
      },
      {
        Header: 'Pause',
        accessor: 'countedBreak',
      },
      {
        Header: 'Total P.',
        accessor: 'countedDuration',
      } /*
      {
        Header: 'TTHS',
        accessor: 'overtime',
      },*/,
      {
        Header: 'HS Contrat',
        accessor: 'contractualOvertime',
      },
      ...employerRules.map(e => ({
        Header: <Element>{e.name}</Element>,
        accessor: `rule_${e.ruleId}`,
      })),
      ...benefitEvents.map(e => ({
        Header: <Element title={buildWorkPeriodEventLabel(e)}>{e.code}</Element>,
        accessor: `event_${e.code}`,
      })),
      {
        Header: 'Evèn.',
        accessor: 'events',
      },
      {
        Header: ' ',
        accessor: 'messages',
      },
    ],
    [events, employerRules],
  )
}

const WeekWorkedDurationCell: React.FC<{
  weekSummary?: UserSummaries['weeks'][0]
  weeklyHoursWorked?: number
  durationFormatter?: (duration: number) => string
}> = ({ weekSummary, durationFormatter = formatDurationAsDecimal }) => {
  const workedTotal = weekSummary?.totals.durations.worked?.total || 0

  if (!durationFormatter) durationFormatter = formatDuration

  const diffBetweenWorkedDurationAndContract = weekSummary?.totals.durations.worked.contract.diff

  return (
    <Element
      textColor={diffBetweenWorkedDurationAndContract ? 'danger' : 'normal'}
      data-tooltip={
        diffBetweenWorkedDurationAndContract
          ? 'Les heures prestées ne correspondent pas au contrat'
          : undefined
      }
    >
      {durationFormatter(workedTotal)}
    </Element>
  )
}

export const DurationDiff: React.FC<{
  duration?: number
  durationToSubstract?: number
  showZero?: boolean
  durationFormatter?: (duration: number) => string
}> = ({ duration, durationToSubstract, showZero, durationFormatter = formatDuration }) => {
  const diff = (duration || 0) - (durationToSubstract || 0)
  if (!durationFormatter) durationFormatter = formatDuration
  return (
    <Element renderAs="span">
      {showZero && !diff ? '0' : diff ? durationFormatter(diff) : ''}
    </Element>
  )
}

export const getRuleValuesFromSummary = (
  employerRules: UserSummaries['weeks'][0]['employerRules'],
  summary: MissionWeekSummary | MissionWeekDaySummary,
  durationFormatter?: (duration: number) => string,
): { [key: string]: string | number } =>
  employerRules.reduce<{
    [key: string]: string | number
  }>((rules, employerRule) => {
    rules[`rule_${employerRule.ruleId}`] =
      employerRule.kind === 'goal'
        ? summary?.employerData.goals[employerRule.name] || ''
        : durationFormatter
        ? durationFormatter(summary?.employerData.durations[employerRule.name] || 0)
        : formatDuration(summary?.employerData.durations[employerRule.name] || 0)
    return rules
  }, {})
