import React from 'react'
import { Button, Container, Element, Icon, Message, Section } from 'react-bulma-components'
import { BadgeCheckIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

export interface FormSuccessProps {
  title?: string | React.ReactElement
  data?: any
  body?: {
    description?: string | React.ReactElement
    render?(data: any): string | React.ReactElement
    actions?: {
      label: string | JSX.Element
      to?: string
    }[]
  }
  reset?(): void
}

const FormSuccess: React.FunctionComponent<FormSuccessProps> = ({ title, body, reset, data }) => {
  React.useEffect(() => {
    return () => reset && reset()
  }, [])

  return (
    <Section>
      <Container>
        <Message color={'success'}>
          <Message.Header>
            <Element>
              <Icon.Text>
                <Icon>
                  <BadgeCheckIcon />
                </Icon>
                <Element renderAs="span">{title}</Element>
              </Icon.Text>
            </Element>
          </Message.Header>
          <Message.Body px={5} py={4}>
            {body?.description && <Element>{body.description}</Element>}
            {body?.render && <Element>{body.render(data)}</Element>}
            <Element mt={4}>
              {body?.actions?.map((action, index) =>
                action.to ? (
                  <Button key={index} renderAs={Link} to={action.to} mr={2}>
                    {action.label}
                  </Button>
                ) : (
                  <Button
                    key={index}
                    onClick={() => {
                      reset && reset()
                    }}
                    mr={2}
                  >
                    {action.label}
                  </Button>
                ),
              )}
            </Element>
          </Message.Body>
        </Message>
      </Container>
    </Section>
  )
}

export default FormSuccess
