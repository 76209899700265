/*
  TEMP HOOK: to be deleted when the summary table will be refactored and use directly data
  provided by sumlmary query
*/

import { useMemo } from 'react'
import { MissionWithSummaries } from '../api/missions'
import sortBy from 'lodash/sortBy'
import { UserWeekSummary } from '../../../backend/src/services/resources/missions/missions.summary.service.types'
import { WorkPeriod } from '../api/work-periods'
import useStore from '../store'
import { FiltersResourcesEnum } from '../store/filters'

/**
 * Checks if two work periods overlap on the same day
 */
const workPeriodsOverlapSameDay = (wp1: WorkPeriod, wp2: WorkPeriod): boolean => {
  const date1 = new Date(wp1.start.date).setHours(0, 0, 0, 0)
  const date2 = new Date(wp2.start.date).setHours(0, 0, 0, 0)
  return date1 === date2
}

/**
 * Groups similar missions based on common characteristics
 */
const groupSimilarMissions = (missions: MissionWithSummaries[]): MissionWithSummaries[] => {
  const missionGroups = missions.reduce((groups, mission) => {
    const key = [
      mission.user?._id,
      mission.interimAgency?._id || 'noAgency',
      mission.employer._id,
    ].join('_')

    if (!groups[key]) {
      groups[key] = []
    }

    // Find or create a compatible subgroup
    let foundCompatibleGroup = false
    for (const subgroup of groups[key]) {
      const workPeriodsOverlap = mission.workPeriods.some(wp1 =>
        subgroup.workPeriods.some(wp2 => workPeriodsOverlapSameDay(wp1, wp2)),
      )
      if (!workPeriodsOverlap) {
        // Add to existing compatible subgroup
        subgroup.workPeriods.push(...mission.workPeriods)
        subgroup.start = new Date(
          Math.min(new Date(subgroup.start).getTime(), new Date(mission.start).getTime()),
        )
        subgroup.end = new Date(
          Math.max(new Date(subgroup.end).getTime(), new Date(mission.end).getTime()),
        )
        subgroup.summary.weeks = { ...subgroup.summary.weeks, ...mission.summary.weeks }
        foundCompatibleGroup = true
        break
      }
    }

    if (!foundCompatibleGroup) {
      // Create new subgroup
      groups[key].push({
        ...mission,
        workPeriods: [...mission.workPeriods],
      })
    }

    return groups
  }, {} as Record<string, MissionWithSummaries[]>)

  // Flatten all subgroups
  return Object.values(missionGroups)
    .flat()
    .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
}

const useMissionsFromSummaryQuery = (data: UserWeekSummary[]): MissionWithSummaries[] => {
  const summaryMinimalMode = useStore(
    s => s.filters[FiltersResourcesEnum.missions].summaryMinimalMode,
  )
  const missionsWithSummaries = useMemo(() => {
    //@ts-ignore
    const reducedMissions = data.reduce(
      //@ts-ignore
      (missions, summaryItem) => {
        if (summaryItem)
          return [
            ...missions,
            ...summaryItem.missions.map(mission => ({
              ...mission,
              summary: summaryItem.totals,
              weekSummary: summaryItem,
            })),
          ]
        return missions
      },
      [],
    ) as MissionWithSummaries[]
    if (summaryMinimalMode) {
      return groupSimilarMissions(reducedMissions)
    } else {
      return reducedMissions
    }
  }, [data, summaryMinimalMode])

  return sortBy(missionsWithSummaries, 'start') // TODO: refactor table to use summary data correctly
}

export default useMissionsFromSummaryQuery
