import React from 'react'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import SelectField from '../../form/fields/select'
import { allDaysOfInterval, weekDaysAsInputOptions } from '../../../utils/date'

interface DisplayedDaysFilterFieldProps {
  start: Date
  end: Date
}

const DisplayedDaysFilterField: React.FC<DisplayedDaysFilterFieldProps> = ({ start, end }) => {
  return (
    <FieldColumn>
      <Field
        label="Jour affiché"
        name="displayDay"
        component={SelectField}
        options={weekDaysAsInputOptions(allDaysOfInterval(start, end))}
      />
    </FieldColumn>
  )
}

export default DisplayedDaysFilterField
