import React from 'react'
import { Element } from 'react-bulma-components'

interface ServiceExplanationProps {
  textColor?: string
}

const ServiceExplanation: React.FC<ServiceExplanationProps> = ({ textColor }) => {
  return (
    <>
      <Element mb={2}>
        Le service est une unité organisationnelle qui permet de regrouper et catégoriser les missions 
        au sein de votre entreprise.
      </Element>
    </>
  )
}

export default ServiceExplanation 